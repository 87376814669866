import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-time-range-picker",
  templateUrl: "./time-range-picker.component.html",
  styleUrls: ["./time-range-picker.component.less"],
})
export class TimeRangePickerComponent implements OnInit {
  @Input() savedTime?: string | undefined;
  @Input() questionId!: string;
  @Output() eventEmitter: EventEmitter<string>;
  public startTime: Date = new Date(0, 0, 0, 10, 0, 0);
  public endTime: Date = new Date(0, 0, 0, 11, 0, 0);
  public showWarningText = false;

  @ViewChild("startTimePicker") startTimePicker: ElementRef | null = null;
  @ViewChild("endTimePicker") endTimePicker: ElementRef | null = null;

  public timeGroup = new UntypedFormGroup({
    startTimeControl: new UntypedFormControl(this.startTime, [Validators.required]),
    endTimeControl: new UntypedFormControl(this.endTime, [Validators.required]),
  });

  constructor() {
    this.eventEmitter = new EventEmitter<string>();
  }

  ngOnInit() {
    /** a time value can be given so we format that into the date type */
    if (this.savedTime) {
      if (this.savedTime !== "") {
        const timeParts = this.savedTime.split("-");
        const startParts = timeParts[0].split(":");
        const endParts = timeParts[1].split(":");
        this.startTime = new Date(0, 0, 0, +startParts[0], +startParts[1], 0);
        this.endTime = new Date(0, 0, 0, +endParts[0], +endParts[1], 0);
      }
    }
  }

  /** converts Date object into string with time hh:mm */
  public getStartTimeString() {
    const timeString = String(this.startTime?.getHours()).padStart(2, "0") + ":" + String(this.startTime?.getMinutes()).padStart(2, "0");
    return timeString;
  }
  public getEndTimeString() {
    const timeString = String(this.endTime?.getHours()).padStart(2, "0") + ":" + String(this.endTime?.getMinutes()).padStart(2, "0");
    return timeString;
  }

  /**
   * converts two strings to date objects to compare value
   * if it matches with the output string format hh:mm-hh:mm
   * then it emits an event with that value
   * @param startTime time input string hh:mm
   * @param endTime time input string hh:mm
   */

  changedInput(startTime: string, endTime: string) {
    if ((!startTime && !endTime) || startTime === endTime) {
      this.showWarningText = false;
      return;
    }

    if (startTime) {
      const startParts = startTime.split(":");
      this.startTime = new Date(0, 0, 0, +startParts[0], +startParts[1], 0);
    }
    if (endTime) {
      const endParts = endTime.split(":");
      this.endTime = new Date(0, 0, 0, +endParts[0], +endParts[1], 0);
    }
    if (startTime < endTime) {
      this.showWarningText = false;
    }
    if (startTime > endTime && endTime) {
      this.showWarningText = true;
      this.endTime = this.startTime;
    }

    const timeRange: string = this.startTime.getHours() + ":" + this.startTime.getMinutes() + "-" + this.endTime.getHours() + ":" + this.endTime.getMinutes();
    if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(timeRange)) {
      this.eventEmitter.emit(timeRange);
    }
  }
}
