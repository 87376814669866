import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ApplicationService } from "../../services/application.service";
import { ActivatedRoute, Router } from "@angular/router";
import { v4 as generateUUID } from "uuid";

export interface DashboardMenu {
  menu: {
    description: string;
    items: {
      label: string;
      route: string;
      index: number;
    }[];
  };
}

@Component({
  selector: "app-screen-content",
  templateUrl: "./content.html",
  styleUrls: ["./content.less"],
})
export class ContentComponent implements OnDestroy, OnInit {
  protected static readonly TITLE = "Dashboard ";

  public id: string = generateUUID();
  public loading = true;

  @ViewChild("children")
  public children: unknown;

  public constructor(public readonly applicationService: ApplicationService, private route: ActivatedRoute, private router: Router) {}

  public ngOnInit() {
    if (this.router.url == "/content") {
      this.applicationService.updateTitle("");
      this.redirect();
    }
  }

  public ngOnDestroy() {
    this.applicationService.unsubscribe(this.id);
  }

  /**
   * Redirect to correct url based on your permissions
   */
  public redirect() {
    const permission = this.applicationService.session.activeRole.name;

    switch (permission) {
      case "coach":
        this.router.navigate(["coach"], { relativeTo: this.route });
        break;

      case "resident":
        this.router.navigate(["resident"], { relativeTo: this.route });
        break;

      case "coordinator":
        this.router.navigate(["coordinator"], { relativeTo: this.route });
        break;

      case "retailer":
        this.router.navigate(["retailer"], { relativeTo: this.route });
        break;

      default:
        this.loading = true;

        setTimeout(() => {
          this.redirect();
          this.loading = false;
        }, 2000);
        break;
    }
  }
}
