import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { ApplicationService } from "./application.service";
import { EnergyConsult } from "../classes/flow/request/EnergyConsult";
import { Coach } from "../classes/flow/session/impl/Coach";
import { Specialty } from "../classes/flow/Questionnaire/Specialty";
import { MutationResult } from "apollo-angular";
import { User } from "../classes/flow/session/impl/User";
import { Resident } from "../classes/flow/session/impl/Resident";

@Injectable({
  providedIn: "root",
})
export class EnergyConsultService {
  public constructor(private readonly applicationService: ApplicationService, private readonly graphqlService: GraphQLService) {}

  /**
   * Saves a property of an energyConsult
   * @param energyConsult The given energyConsult to update
   * @param key The property of the energyConsult to update
   * @returns The result that include the id
   */
  public async save(energyConsult: EnergyConsult, key?: string) {
    let energyConsultData: any = {
      appointmentDate: energyConsult.appointmentDate,
      coachId: energyConsult.coach ? energyConsult.coach.id : null,
      houseNumber: energyConsult.houseNumber,
      houseNumberSuffix: energyConsult.houseNumberSuffix,
      message: energyConsult.message,
      postalCode: energyConsult.postalCode,
      rejectReason: energyConsult.rejectReason,
      requestStateId: await this.getEnergyConsultStateId(energyConsult),
      changes: energyConsult.changes,
    };

    if (key) energyConsultData = { [key]: energyConsultData[key] };

    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: ${this.graphqlService.parseObject(energyConsultData)}
        }) {
          value {
            id
          }
          messages {
            message
          }
        }
      }`
    );
  }

  /**
   *
   * @param id
   * @returns streetname of the request id that was given
   */
  public async getStreetNameOfConsult(id: number) {
    return await this.graphqlService.query(`
    query {
      energyConsults {
        value (where: {id: {eq: ${id}}}) {
          id
          streetName
        }
        messages {
          message
        }
      }
    }`);
  }
  /**
   * Gets data of particular energyConsults
   * @param where The where-clause to specify the right energyConsult(s)
   * @returns The result with the data of the energyConsult
   */
  private async getEnergyConsultData(where = ""): Promise<MutationResult<any>> {
    return await this.graphqlService.query(
      `query {
              energyConsults {
                value ${where} {
                  id
                  changes {
                    lastChange {
                      userId
                      time
                    }
                    fullDetails{
                      key
                      value {
                        userId
                        time
                      }
                    }
                  }
                  requestState {
                    id
                    name
                  }
                  specialtyId
                  residentId
                  resident {
                    id
                    userId
                    firstName
                    lastName
                    email
                    phoneNumber
                  }
                  requestDate
                  ${this.applicationService.session.activeRole?.name == "coach" || this.applicationService.session.activeRole?.name == "coordinator" ? "rejectReason" : ""}
                  ${this.applicationService.session.activeRole?.name == "coordinator" ? "disapprovalReason" : ""}
                  postalCode
                  message
                  houseNumber
                  houseNumberSuffix
                  appointmentDate
                  statusLastChanged
                  coachId
                  coach {
                    id
                    firstName
                    lastName
                    email
                  }
                  specialty {
                    id
                    name
                    description
                    order
                  }
                }
                messages {
                  message
                }
              }
            }`
    );
  }

  /**
   * Gets energyConsult objects of the given data
   * @param energyConsultData data that include the energyConsult data
   * @returns An array of energyConsult objects
   */
  private getEnergyConsults(energyConsultData: MutationResult<any>): EnergyConsult[] {
    return energyConsultData.data["energyConsults"].value.map((data: any): EnergyConsult => {
      let coach;

      if (data.coach) {
        coach = new Coach({
          id: data.coach.id,
          userEmail: data.coach.email,
          firstName: data.coach.firstName,
          lastName: data.coach.lastName,
        });
      }

      const specialty: Specialty = {
        id: data.specialty.id,
        name: data.specialty.name,
        description: data.specialty.description,
        order: data.specialty.order,
      };

      const resident = new Resident({
        id: data.resident.userId,
        firstName: data.resident.firstName,
        lastName: data.resident.lastName,
        phoneNumber: data.resident.phoneNumber,
        email: data.resident.email,
      });

      return new EnergyConsult(
        data.id,
        data.message,
        data.postalCode,
        data.houseNumber,
        new Date(data.requestDate),
        {
          id: data.requestState.id,
          name: data.requestState.name,
        },
        specialty,
        resident,
        data.houseNumberSuffix,
        data.streetName,
        new Date(data.statusLastChanged),
        data.rejectReason,
        coach,
        data.appointmentDate ? new Date(data.appointmentDate) : undefined,
        data.disapprovalReason,
        this.graphqlService.createChangesObject(data.changes)
      );
    });
  }

  /**
   * Gets energyConsults based on the given user
   * @param user The user
   * @returns An array with energyConsults
   */
  public async loadByUser(user: User): Promise<EnergyConsult[]> {
    const role = this.applicationService.session.activeRole.name;
    const energyConsults = await this.getEnergyConsultData(role === "coordinator" ? "" : `(where: {${role === "coach" ? "coachId" : "residentId"}: {eq: ${user.id}}})`);
    return this.getEnergyConsults(energyConsults);
  }

  public async loadInspectingAsCoord(id: string, role: string): Promise<EnergyConsult[]> {
    let requests;
    role === "coach" || role === "coordinator"
      ? (requests = await this.getEnergyConsultData(`(where: {coach_id: {_eq: "${id}"}})`))
      : (requests = await this.getEnergyConsultData(`(where: {resident_id: {_eq: "${id}"}})`));
    // const requests = await this.getRequestData(`(where: {id: {_eq: "${id}"}})`);
    return this.getEnergyConsults(requests);
  }

  /**
   * Gets the accepted energyConsults of a given coach
   * @param coach The coach
   * @returns An array with energyConsults
   */
  public async loadAcceptedByCoach(coach: Coach): Promise<EnergyConsult[]> {
    const energyConsults = await this.getEnergyConsultData(`(where: {coachId: {eq: ${coach.id}}})`);
    return this.getEnergyConsults(energyConsults);
  }

  /**
   * Gets a request based on the given id
   * @param id The id of the needed request
   * @returns A request
   */
  public async loadById(id: number): Promise<EnergyConsult> {
    const requests = await this.getEnergyConsultData(`(where: {id: {eq: ${id}}})`);
    return this.getEnergyConsults(requests)[0];
  }

  /**
   * Get all requests
   * @returns An array with requests
   */
  public async load(): Promise<EnergyConsult[]> {
    const requests = await this.getEnergyConsultData();
    return this.getEnergyConsults(requests);
  }

  /**
   * Get all requests
   * @returns An array with requests
   */
  public async sendPDFToResident(energyConsultId: number) {
    const res = await this.graphqlService.query(`
      mutation {
        sendReportAsPDF(energyConsultId: ${energyConsultId}) {
          value {
            email
          }
          messages {
            message
          }
        }
      }`);
    if (!res.data["sendReportAsPDF"]?.value?.email) {
      throw new Error("Something went wrong");
    }
  }

  /**
   * Sends a PDF request to the coach. If the request failed, there will be thrown an error.
   * @param requestId The id of the request
   */
  public async sendPdfRequestToCoach(requestId: number) {
    const res = await this.graphqlService.query(`mutation MyMutation { send_report_as_pdf_to_coach(input: {request_id: "${requestId}"}) { email_address } }`);
    if (!res.data["send_report_as_pdf_to_coach"]?.email_address) {
      throw new Error("Something went wrong");
    }
  }

  public async checkDoneStatus(requestId: string) {
    const res = await this.graphqlService.query(
      `query{
        requests(where: {id: {_eq: "${requestId}"}})  {
          state
          changes {
            lastChange {
              userId
              time
            }
            fullDetails {
              key
              value {
                userId
                time
              }
            }
        }
        messages{
          message
        }
      }
      `
    );
    return res.data["requests"][0].state;
  }

  /**
   * Sets the state of the energyConsult to done
   * @param energyConsult The id of the energyConsult
   */
  public async setState(energyConsult: EnergyConsult) {
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            requestStateId: ${await this.getEnergyConsultStateId(energyConsult)}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        }) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Disapproves the given energyConsult
   * @param energyConsult The energyConsult to disapprove
   */
  public async disapproveEnergyConsult(energyConsult: EnergyConsult) {
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            requestStateId: ${await this.getEnergyConsultStateId(energyConsult)}
            disapprovalReason: "${energyConsult.disapprovalReason}"
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        }) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Marks all energyConsult from the specified resident as pending to be deleted.
   * Note that this method directly deletes those energyConsults that can be deleted without revision by a coordinator.
   * @param residentId The id of the resident whose energyConsults should be deleted (or marked for deletion)
   */
  public async markAllEnergyConsultsAsDeletedForResident(residentId: string) {
    await this.graphqlService.query(
      `mutation {
        update_energyConsults(where: {_and: [{resident_id: {_eq: "${residentId}"}}, {state: {_in: ["Open", "New"]}}]}, _set: {state: "Deleted"}){
          returning {
            id
          }
          messages{
            message
          }
        }
      }`
    );
    await this.graphqlService.query(
      `mutation {
        update_energyConsults(where: {_and: [{resident_id: {_eq: "${residentId}"}}, {state: {_nin: ["Open", "New", "Deleted"]}}]}, _set: {state: "PendingDeleted"}){
          returning {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Deletes a specific energyConsult
   * @param energyConsult The energyConsult
   */
  public async delete(energyConsult: EnergyConsult) {
    await this.graphqlService.query(
      `mutation {
        deleteEnergyConsult(energyConsultId: ${energyConsult.id}) {
          value {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Gets the id of the current energyConsult state
   * @param energyConsult The energyConsult
   * @returns The id of the current energyConsult state
   */
  public async getEnergyConsultStateId(energyConsult: EnergyConsult): Promise<number> {
    const result = await this.graphqlService.query(
      `
      query {
        requestStates {
          value(where: {name: {eq: "${energyConsult.state.name}"}}) {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
            }
          }
        }`
    );
    return result.data.requestStates.value[0].id;
  }

  /**
   * Creates a new energy consult
   * @param energyConsult The new energy consult
   * @returns Data of the new energy consult
   */
  public async create(energyConsult: EnergyConsult) {
    return await this.graphqlService.query(
      `mutation{
        addEnergyConsult(energyConsult: {
          email: "${energyConsult.resident?.email}",
          firstName: "${energyConsult.resident?.firstName}",
          lastName: "${energyConsult.resident?.lastName}",
          houseNumber: ${energyConsult.houseNumber},
          houseNumberSuffix: "${energyConsult.houseNumberSuffix}",
          phoneNumber: "${energyConsult.resident?.phoneNumber}",
          postalCode: "${energyConsult.postalCode}",
          specialtyId: ${energyConsult.specialty.id},
          message: "${energyConsult.message}",
        }
        ) {
          value{
            id
          }
          messages{
            message
          }
        }
      }`,
      true
    );
  }
}
