import { Entity } from "../classes/flow/base/Entity";

/**
 * Compare two entities
 * @param firstEntity The first entity
 * @param secondEntity The second entity
 * @returns True if the entities are equal, false otherwise
 */
export const compareEntities = (firstEntity: Entity, secondEntity: Entity): boolean => {
  return firstEntity.id == secondEntity.id;
};