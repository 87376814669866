<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column">
      <h2>{{ "FORMS.NEW_QUESTIONNAIRE.TITLE" | translate }}</h2>
      <br />

      <app-questionnaire-form [formGroup]="questionnaireForm"></app-questionnaire-form>

      <button mat-raised-button [disabled]="!questionnaireForm.valid" (click)="saveQuestionnaire()">{{ "FORMS.NEW_QUESTIONNAIRE.SAVE" | translate }}</button>
    </div>
  </div>
</div>