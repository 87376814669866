<div id="dashboardContainer" class="container">
  <div class="row">
    <div id="hintContainer" class="col-md">
      <div class="row justify-content-center align-self-center">
        <div class="col hint justify-content-md-center">
          <mat-icon>do_not_disturb_on</mat-icon>
          <label>= {{ "COMPONENTS.DASHBOARD.CANCEL" | translate }}</label>
        </div>

        <div class="col hint justify-content-md-center">
          <mat-icon>delete</mat-icon>
          <label>= {{ "COMPONENTS.DASHBOARD.DELETE" | translate }}</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div id="filterContainer" class="col-md text-center my-3">
      <app-badge-filters [filters]="filters" (filter)="filterRequests()"></app-badge-filters>
      <mat-form-field appearance="outline">
        <input #inputFilterElement matInput (keyup)="inputFilter(inputFilterElement.value)" placeholder="{{ 'TABLES.GENERAL.FILTER' | translate }}..." />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div id="sorterContainer" class="col-md text-md-center my-3">
      <mat-radio-button id="sortButton" (click)="sortRequests('Date')">{{"COMPONENTS.DASHBOARD.SORT_REQUESTDATE" | translate}}</mat-radio-button>
      <mat-icon *ngIf="sortAscending && currentSortMethod === 'Date'">south</mat-icon>
      <mat-icon *ngIf="!sortAscending && currentSortMethod === 'Date'">north</mat-icon>
      <mat-radio-button id="sortButton2" (click)="sortRequests('lastStateChangeDate')" checked>{{"COMPONENTS.DASHBOARD.SORT_REQUESTCHANGED" | translate}}</mat-radio-button>
      <mat-icon *ngIf="sortAscending && currentSortMethod === 'lastStateChangeDate' ">south</mat-icon>
      <mat-icon *ngIf="!sortAscending && currentSortMethod === 'lastStateChangeDate'">north</mat-icon>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <span class="title">
        <button mat-icon-button (click)="ngAfterContentInit()">
          <mat-icon>refresh</mat-icon>
        </button>
        {{ applicationService.session.activeRole.name === 'coordinator' ? ("COMPONENTS.DASHBOARD.ALL_REQUESTS" | translate) : ("COMPONENTS.DASHBOARD.YOUR_REQUESTS" |
        translate) }}:
      </span>
    </div>
    <div class="col-md" *ngIf="applicationService.session.activeRole.name === 'coordinator'">
      <div class="card">
        <button mat-flat-button (click)="openDialog(requestStatistics)">{{ "COMPONENTS.PROFILE.REQUESTSTATS.OPENREQUESTDETAILS" | translate }}</button>
      </div>
    </div>
    <div class="col-md text-md-end" *ngIf="applicationService.session.activeRole.name === 'coordinator' || applicationService.session.activeRole.name === 'coach'">
      <span class="title">
        <button mat-icon-button (click)="setViewMode(true)">
          <mat-icon>grid_view</mat-icon>
        </button>
        <button mat-icon-button (click)="setViewMode(false)">
          <mat-icon>view_list</mat-icon>
        </button>
      </span>
    </div>
  </div>

  <div class="row d-flex justify-content-center" id="requestContainer">
    <div *ngIf="viewTileMode" class="row">
      <div *ngIf="submitRequest" class="col-md-12 col-lg-6 col-xl-4 d-flex align-items-center">
        <div class="request fake" [routerLink]="['/request']">
          <span>
            <mat-icon>add</mat-icon>
            <label>{{ "COMPONENTS.DASHBOARD.NEW_ENERGY_CONSULT" | translate }}</label>
          </span>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4 custom-col-padding" *ngFor="let i of filteredRequests">
        <app-energy-consult
          (initRequests)="ngAfterContentInit()"
          [energyConsult]="i"
          [actions]="applicationService.session.user!.status === 'PendingDeleted' ? 3 : 0"
        ></app-energy-consult>
      </div>
    </div>

    <div *ngIf="!viewTileMode" class="row">
      <div *ngFor="let states of splittedRequests" class="mt-3">
        <hr />
        <h5 class="">{{"COMPONENTS.TILE.STATUSES." + states[0][0].state.name | translate}}</h5>
        <div *ngFor="let specialties of states" class="m-2">
          <h6 class="d-flex align-items-center mt-2"><mat-icon>chevron_right</mat-icon> {{specialties[0].specialty!.name}}</h6>
          <app-table
            #requestTable
            id="REQUESTS"
            [data]="specialties"
            [pageSizes]="[10, 20, 50, 100]"
            [headers]="[
          {visualName: 'COACH', resolver: displayCoach},
          {visualName: 'DATE', resolver: displayDate},
          {visualName: 'APPOINTMENT', resolver: displayAppointment},
          {visualName: 'LOCATION', mappedTo: 'postalCode'},
          {visualName: 'OPEN', link: {baseUrl: applicationService.session.activeRole.name === 'coach' ? '/content/coach/request/' : '/content/coordinator/request/' , params: ['id']}},
          ]"
          ></app-table>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="filteredRequests.length < 1" class="text-center my-3">{{ "COMPONENTS.DASHBOARD.NO_REQUESTS" | translate}}</div>
</div>

<ng-template #requestStatistics>
  <div class="container">
    <div class="row">
      <div class="col-md">
        <span>{{ "COMPONENTS.PROFILE.REQUESTSTATS.TOTAL" | translate }}{{ getNewRequest(requests).length }}</span>
        <br />
        <div class="col-md">
          <style>
            .mat-header-cell {
              justify-content: center !important;
            }
            .mat-cell {
              justify-content: center !important;
              text-align: center;
            }
          </style>
          <mat-table [dataSource]="getNewRequestByType()">
            <ng-container matColumnDef="specialiteit">
              <mat-header-cell *matHeaderCellDef> {{ "COMPONENTS.PROFILE.REQUESTSTATS.SPECIALTIES" | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let entry"> {{ entry.name }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="aantal" style="text-align: right">
              <mat-header-cell *matHeaderCellDef> {{ "COMPONENTS.PROFILE.REQUESTSTATS.TOTALCOUNT" | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let entry"> {{ entry.value }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['specialiteit', 'aantal']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['specialiteit', 'aantal']"></mat-row>
          </mat-table>
          <br />
          <button class="align-middle w-100" mat-flat-button (click)="coachReminder()">{{ "COMPONENTS.PROFILE.REQUESTSTATS.SENDREMINDER" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
