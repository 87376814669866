import { Coach } from "../session/impl/Coach";
import { Resident } from "../session/impl/Resident";
import { Specialty } from "../Questionnaire/Specialty";
import { Changes } from "../base/Changes";
import { RequestState, RequestStates } from "./RequestStates";
import { Request } from "./Request";

export class EnergyConsult extends Request {
  public constructor(
    id: number,
    public message: string,
    public postalCode: string,
    public houseNumber: string,
    requestDate: Date,
    state: RequestState,
    public specialty: Specialty,
    public resident?: Resident,
    public houseNumberSuffix?: string,
    public streetName?: string,
    lastStateChangeDate?: Date,
    public rejectReason?: string,
    public coach?: Coach,
    public appointmentDate?: Date,
    public disapprovalReason?: string,
    changes?: Changes
  ) {
    super(id, requestDate, state, lastStateChangeDate, changes);
  }

  /**
   * Gets the location of an energyConsult
   */
  public get location(): string {
    return this.state.name == RequestStates.NEW
      ? this.postalCode
      : this.postalCode.toUpperCase() + ", " + (this.streetName ? this.streetName + " " : "") + this.houseNumber + (this.houseNumberSuffix ?? "");
  }

  public get canBeClaimed(): boolean {
    return !(this.coach && this.state.name != RequestStates.NEW) && super.canBeClaimed;
  }

  /**
   * Gets the minimum date for an appointment
   * @returns The minimum date
   */
  public get minimalAppointmentDate(): Date {
    return new Date();
  }

  public get canCoachBeChanged(): boolean {
    return this.state.name === RequestStates.NEW || this.state.name === RequestStates.DATE || this.state.name === RequestStates.PICKED;
  }
}
