<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center">
      <h2>Replacement Claim Maken</h2>
      <p>Hier komt de omschrijving van de claim</p>
      <br />
      <form [formGroup]="form" (ngSubmit)="saveFormGroup()">
        <h3>Nieuw Apparaat</h3>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Type</mat-label>
              <mat-select formControlName="replacementType">
                <mat-option *ngFor="let option of typeOptions" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-select>
              <app-error [control]="form.controls.replacementType"></app-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="form.controls.replacementType.value">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Categorie</mat-label>
              <mat-select formControlName="replacementCategory">
                <mat-option *ngFor="let option of replacementCategoryFilteredOptions | async" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-select>
              <app-error [control]="form.controls.replacementCategory"></app-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Omschrijving</mat-label>
              <textarea matInput formControlName="replacementDescription"></textarea>
              <app-error [control]="form.controls.replacementDescription"></app-error>
            </mat-form-field>
          </div>
        </div>

        <ng-container formArrayName="replacementClaimReturn">
          <h3>Retour apparaten</h3>
          <ng-container *ngFor="let returnControl of form.controls.replacementClaimReturn.controls; let i = index" [formGroup]="returnControl">
            <h5>Retour apparaat {{ i + 1 }} <button type="button" mat-raised-button (click)="deleteReturnControl(i)" *ngIf="i">Verwijderen</button></h5>

            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="returnType">
                    <mat-option *ngFor="let option of typeOptions" [value]="option.id">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                  <app-error [control]="returnControl.controls.returnType"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="returnControl.controls.returnType.value">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Categorie</mat-label>
                  <mat-select formControlName="returnCategory">
                    <mat-option *ngFor="let option of returnCategoryFilteredOptions[i] | async" [value]="option.id">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                  <app-error [control]="returnControl.controls.returnCategory"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Omschrijving</mat-label>
                  <textarea matInput formControlName="returnDescription"></textarea>
                  <app-error [control]="returnControl.controls.returnDescription"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-2">
                <button type="button" mat-raised-button color="primary" (click)="openQRDialog(returnControl)">QR Code scannen</button>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>QR Code</mat-label>
                  <textarea matInput formControlName="returnQR"></textarea>
                  <app-error [control]="returnControl.controls.returnQR"></app-error>
                </mat-form-field>
               </div>

            </div>
          </ng-container>
          <button type="button" mat-raised-button (click)="addReturnControl()">Retour apparaat toevoegen</button>
        </ng-container>

        <button type="submit" mat-raised-button>Opslaan</button>
      </form>
    </div>
  </div>
</div>
