import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { SnackbarService } from "../../services/snackbar.service";
import { Questionnaire } from "../../classes/flow/Questionnaire/Questionnaire";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-new-questionnaire",
  templateUrl: "./new-questionnaire.component.html",
  styleUrls: ["./new-questionnaire.component.less"],
})
export class NewQuestionnaireComponent {
  public questionnaireForm: UntypedFormGroup = new UntypedFormGroup({
    specialties: new UntypedFormControl([], Validators.required),
    title: new UntypedFormControl("", Validators.required),
    description: new UntypedFormControl("", Validators.required),
    questionnaireType: new UntypedFormControl("", Validators.required)
  });

  constructor(
    private readonly translateService: TranslateService,
    private readonly questionnaireService: QuestionnaireService, 
    private readonly snackbarService: SnackbarService, 
    private readonly router: Router,) {
  }

  /**
   * Saves the user input as a new questionnaire
   */
  public async saveQuestionnaire() {
    if (this.questionnaireForm.valid) {
      try {
        await this.questionnaireService.createQuestionnaire(this.makeQuestionnaire());
        this.router.navigate(["content/coordinator/all-questionnaires"]);
      } catch (error) {
        this.snackbarService.open(this.translateService.instant("FORMS.NEW_QUESTIONNAIRE.FAILURE_MESSAGE"));
      }
    }
  }

  /**
   * Builds a questionnaire from the individual input values
   * @returns The questionnaire based on the user's input
   */
  private makeQuestionnaire(): Questionnaire {
    const data = this.questionnaireForm.value;
    return {
      active: true,
      title: data.title,
      description: data.description,
      questionnaireType: data.questionnaireType,
      specialties: data.specialties,
    };
  }
}
