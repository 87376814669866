import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.less"]
})
export class InputErrorComponent {

  @Input() 
  public control?: AbstractControl;

  public constructor(private readonly translateService: TranslateService) {
  }

  /**
   * Gets an error message
   */
  public get errorMessage(): string {
    return this.control ? this.obtainErrorMessage() : "";
  }

  /**
   * Gets an error message for the given control
   */
  private obtainErrorMessage(): string {
    if(this.control) {
      switch (true) {
        case this.control.hasError("required"):
          return this.translateService.instant("FORMS.ERRORS.REQUIRED");
        case this.control.hasError("incorrect"):
          return this.translateService.instant("FORMS.ERRORS.INCORRECT");
        case this.control.hasError("min"):
          return this.translateService.instant("FORMS.ERRORS.MIN") + `${this.control.errors?.min.min}.`;
        case this.control.hasError("max"):
          return this.translateService.instant("FORMS.ERRORS.MAX") +  `${this.control.errors?.max.max}.`;
        case this.control.hasError("email"):
          return this.translateService.instant("FORMS.ERRORS.EMAIL") +  " (voorbeeld@email.nl)";
        case this.control.hasError("phoneNumber"):
          return this.translateService.instant("FORMS.ERRORS.PHONE");
        case this.control.hasError("postalCode"):
          return this.translateService.instant("FORMS.ERRORS.POSTAL_CODE") + " (1234 AB)";
        case this.control.hasError("iban"):
          return this.translateService.instant("FORMS.ERRORS.IBAN");
        case this.control.hasError("decimal"):
          return this.translateService.instant("FORMS.ERRORS.DECIMAL");
        case this.control.hasError("integer"):
          return this.translateService.instant("FORMS.ERRORS.INTEGER");
        case this.control.hasError("operator"):
          return this.translateService.instant("FORMS.ERRORS.OPERATOR");
        case this.control.hasError("minlength"):
          return this.translateService.instant("FORMS.ERRORS.MIN_LENGTH") + ": " +  this.control.errors?.minlength.requiredLength;
        case this.control.hasError("maxlength"):
          return this.translateService.instant("FORMS.ERRORS.MAX_LENGTH") + ": " +  this.control.errors?.maxlength.requiredLength;
        case this.control.hasError("alphaCharacters"):
          return this.translateService.instant("FORMS.ERRORS.TEXT");
        case this.control.hasError("houseNumber"):
          return this.translateService.instant("FORMS.ERRORS.HOUSE_NUMBER");
      }
    }
    return "";
  }
}
