import { Component, Inject, TemplateRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogService } from "../../services/dialog.service";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.less"],
})
export class DialogComponent {
  public templateRef: TemplateRef<unknown>;
  public data: unknown;
  public dialogRef: MatDialogRef<unknown>;

  public constructor(@Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<unknown>, public readonly dialogService: DialogService) {
    this.templateRef = data.template;
    this.data = data.data;
    this.dialogRef = dialogRef;
  }

  public closeDialog() {
    this.dialogService.close();
  }
}
