import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ReplacementService } from "../../../../services/replacement.service";
import { BehaviorSubject, Subscription, filter } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { QrScannerComponent } from "../../../../components/qr-scanner/qr-scanner.component";

@Component({
  selector: "app-replacement-claim-new",
  templateUrl: "./replacement-claim-new.component.html",
  styleUrls: ["./replacement-claim-new.component.less"],
})
export class ReplacementClaimNewComponent implements OnInit {
  public form: TReplacementClaimNewControl;

  public categoryOptions: IApplianceCategory[];
  public typeOptions: IApplianceType[];

  public replacementCategoryFilteredOptions: BehaviorSubject<IApplianceCategory[]>;
  public replacementTypeSubscription?: Subscription;
  public returnCategoryFilteredOptions: BehaviorSubject<IApplianceCategory[]>[];
  public returnTypeSubscriptions: Subscription[];

  constructor(private fb: FormBuilder, private replacementService: ReplacementService, public dialog: MatDialog) {
    this.form = this.fb.group({
      replacementType: this.fb.control<number | null>(null, [Validators.required]),
      replacementCategory: this.fb.control<number | null>(null, [Validators.required]),
      replacementDescription: this.fb.control<string>("", [Validators.required]),
      replacementClaimReturn: this.fb.array<TReplacementClaimReturnControl>([], [Validators.required]),
    });
    this.categoryOptions = [];
    this.typeOptions = [];

    this.replacementCategoryFilteredOptions = new BehaviorSubject<IApplianceCategory[]>(this.categoryOptions);
    this.returnCategoryFilteredOptions = [];
    this.returnTypeSubscriptions = [];
  }

  async ngOnInit(): Promise<void> {
    // Get types and categories
    this.typeOptions = await this.replacementService.getTypes();
    this.categoryOptions = await this.replacementService.getCategories();

    // Initialize category options
    this.replacementCategoryFilteredOptions = new BehaviorSubject<IApplianceCategory[]>(this.categoryOptions);
    this.returnCategoryFilteredOptions = [];

    // Subscribe to new appliance type to select categories
    this.form.controls.replacementType.valueChanges.subscribe((controlValue) => {
      let options: IApplianceCategory[] = [];
      if (controlValue === null) options = this.categoryOptions;
      options = this.categoryOptions.filter((optionValue) => controlValue === optionValue.typeId);
      this.replacementCategoryFilteredOptions.next(options);
    });

    // Default 1 control
    this.addReturnControl();
  }

  /**
   * Get a new return control
   * @param defaultCategory initial value
   * @param defaultType initial value
   * @param defaultDescription initial value
   * @param defaultQR initial value
   * @returns
   */
  private getReplacementClaimReturnControl(defaultCategory?: number, defaultType?: number, defaultDescription?: string, defaultQR?: string): TReplacementClaimReturnControl {
    return this.fb.group({
      returnType: this.fb.control<number | null>(defaultType ?? null, [Validators.required]),
      returnCategory: this.fb.control<number | null>(defaultCategory ?? null, [Validators.required]),
      returnDescription: this.fb.control<string>(defaultDescription ?? "", [Validators.required]),
      returnQR: this.fb.control<string>(defaultQR ?? "", [Validators.required]),
    });
  }

  /**
   * Add a control to the form array, make a new behaviorsubject, subscribe to the type value, and save the subscription
   */
  addReturnControl() {
    const newControl = this.getReplacementClaimReturnControl();
    const index = this.returnCategoryFilteredOptions.push(new BehaviorSubject<IApplianceCategory[]>(this.categoryOptions));
    this.form.controls.replacementClaimReturn.push(newControl);
    const sub = newControl.controls.returnType.valueChanges.subscribe((controlValue) => {
      let options: IApplianceCategory[] = [];
      if (controlValue === null) options = this.categoryOptions;
      options = this.categoryOptions.filter((optionValue) => controlValue === optionValue.typeId);
      this.returnCategoryFilteredOptions[index - 1].next(options);
    });
    this.returnTypeSubscriptions.push(sub);
  }

  /**
   * Remove a control from the formArray, allong with it's subscriptions and behavioursubject.
   * @param index the index of the control to be removed
   */
  deleteReturnControl(index: number) {
    this.form.controls.replacementClaimReturn.removeAt(index);
    this.returnCategoryFilteredOptions = this.returnCategoryFilteredOptions.slice(index, index + 1);
    this.returnTypeSubscriptions[index].unsubscribe();
    this.returnTypeSubscriptions = this.returnTypeSubscriptions.slice(index, index + 1);
  }

  /**
   * Submit handler (WIP)
   */
  saveFormGroup() {
    console.log(this.form.value);
  }

  openQRDialog(returnControl: TReplacementClaimReturnControl) {
    this.dialog
      .open(QrScannerComponent)
      .afterClosed()
      .pipe(filter((val) => !!val))
      .subscribe((e) => {
        returnControl.controls.returnQR.patchValue(e);
      });
  }
}

export type TReplacementClaimReturnControl = FormGroup<{
  returnType: FormControl<number | null>;
  returnCategory: FormControl<number | null>;
  returnDescription: FormControl<string | null>;
  returnQR: FormControl<string | null>;
}>;

export type TReplacementClaimNewControl = FormGroup<{
  replacementType: FormControl<number | null>;
  replacementCategory: FormControl<number | null>;
  replacementDescription: FormControl<string | null>;
  replacementClaimReturn: FormArray<TReplacementClaimReturnControl>;
}>;

export interface IApplianceType {
  id: number;
  name: string;
  code: string;
}

export interface IApplianceCategory {
  id: number;
  name: string;
  typeId: number;
  expired: boolean;
}
