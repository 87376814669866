import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "../../../services/application.service";

@Component({
  selector: "app-screen-403",
  templateUrl: "./403.html",
  styleUrls: ["./403.less"],
})
export class Error_403Component {
  public constructor(public readonly applicationService: ApplicationService, public readonly translateService: TranslateService) {
    this.applicationService.updateTitle("Oops!");
  }
}
