<div class="container">
  <div class="row">
    <div class="col-md">
      <h3>{{ "COMPONENTS.LOGGED_OUT.TITLE" | translate }}</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-md mt-4">
      <button mat-raised-button [routerLink]="['/request']">{{ "COMPONENTS.LOGGED_OUT.HOME_PAGE_BUTTON" | translate }}</button>
    </div>
  </div>
</div>