<div class="container">
  <mat-tab-group mat-stretch-tabs="true" class="col-xl-6 col-md-10" style="border-color: var(--darker-outline-color)">
    <mat-tab label="{{ getStartTimeString() }}">
      <mat-form-field appearance="outline" class="d-flex justify-content-start mt-3">
        <mat-label>{{ "COMPONENTS.COACH_REQUEST.STARTING_TIME" | translate }}</mat-label>
        <input
          matInput
          type="time"
          #startTimeInput
          placeholder="{{ getStartTimeString() }}"
          [format]="24"
          [ngxTimepicker]="startTimePicker"
          required
          class="form-control"
          readonly
        />
        <mat-icon (click)="startTimePicker.open()" matSuffix>schedule</mat-icon>
        <ngx-material-timepicker #startTimePicker [enableKeyboardInput]="true" (closed)="changedInput(startTimeInput.value, endTimeInput.value)"> </ngx-material-timepicker>
      </mat-form-field>
      <div class="text-danger" *ngIf="showWarningText">{{"COMPONENTS.ALL_QUESTIONNAIRES.TIME_PICKER_ERROR" | translate}}</div>
    </mat-tab>

        <mat-tab label={{getEndTimeString()}}>
            <mat-form-field appearance="outline" class="d-flex justify-content-start mt-3">
                <mat-label>{{ "COMPONENTS.COACH_REQUEST.ENDING_TIME" | translate}}</mat-label>
                <input matInput type="time" #endTimeInput 
                    placeholder="{{getEndTimeString()}}"
                    [format]="24" 
                    [ngxTimepicker]="endTimePicker" 
                    required class="form-control"
                    readonly>
                    <mat-icon (click)="endTimePicker.open()" matSuffix>schedule</mat-icon>
                <ngx-material-timepicker #endTimePicker 
                    [enableKeyboardInput]="true" 
                    (closed)="changedInput(startTimeInput.value, endTimeInput.value)">
                </ngx-material-timepicker>
            </mat-form-field>
            <div class="text-danger" *ngIf="showWarningText">{{"COMPONENTS.ALL_QUESTIONNAIRES.TIME_PICKER_ERROR" | translate}}</div>
        </mat-tab>
    </mat-tab-group>
</div>
