import { Changes } from "../../base/Changes";
import { Role } from "../Session";
import { User } from "./User";

export class Coordinator extends User {
  public id: number;

  public constructor({
    userId,
    roles = [],
    id,
    userEmail,
    firstName,
    lastName,
    accessStartingDate,
    accessEndDate,
    changes
  }: {
    userId?: number;
    id: number;
    userEmail: string;
    roles?: Role[];
    firstName?: string;
    lastName?: string;
    accessStartingDate?: Date;
    accessEndDate?: Date;
    changes?: Changes
  }) {
    super({ firstName, lastName, email: userEmail, id: userId, roles, accessStartingDate: accessStartingDate, accessEndDate: accessEndDate, changes: changes });
    this.id = id;
  }
}
