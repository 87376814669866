import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { alphaValidator } from "../../../../validators/alpha";
import { iBanValidator } from "../../../../validators/iBAN";
import { Retailer } from "../../../../classes/flow/session/impl/Retailer";
import { MessageService } from "../../../../services/message.service";
import { RetailersService } from "../../../../services/retailers.service";
import { postalCodeValidator } from "../../../../validators/postalCode";
import { Location } from "@angular/common";
@Component({
  selector: "app-new-retailer",
  templateUrl: "./new-retailer.component.html",
  styleUrls: ["./new-retailer.component.less"],
})
export class NewRetailerComponent {
  public formGroup: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    firstName: new FormControl("", [Validators.required, alphaValidator]),
    lastName: new FormControl("", [Validators.required, alphaValidator]),
    postalCode: new FormControl("", [Validators.required, postalCodeValidator]),
    city: new FormControl("", [Validators.required, alphaValidator]),
    iban: new FormControl("", [Validators.required, iBanValidator]),
  });

  constructor(private readonly retailersService: RetailersService, private readonly messageService: MessageService, private _location: Location) {}

  /**
   * Saves the data of a new retailer
   */
  public async saveNewRetailer() {
    const result = await this.retailersService.addRetailer(
      new Retailer({
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        email: this.formGroup.value.email,
        postalCode: this.formGroup.value.postalCode,
        city: this.formGroup.value.city,
        iBAN: this.formGroup.value.iBAN,
      })
    );
    this.messageService.handle(result.messages, "/content/coordinator/dashboard");
    if (result) {
      this._location.back();
    }
  }
}
