<div class="container">
  <div class="row">
    <div class="row">
      <h4>{{ "COMPONENTS.ERROR_PAGES.404.MESSAGE" | translate }}</h4>
    </div>
    <div class="row">
      <h5>{{ "COMPONENTS.ERROR_PAGES.404.DESCRIPTION" | translate }}</h5>
      <h5>{{ "COMPONENTS.ERROR_PAGES.404.ADVICE" | translate }}</h5>
    </div>
    <h5>
      <div class="row"><a routerLink="/">{{ "COMPONENTS.ERROR_PAGES.404.ACTION" | translate }}</a></div>
    </h5>
</div>
</div>