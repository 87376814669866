<meta name="viewport" content="width=device-width" />
<meta name="theme-color" />
<ng-container *ngIf="!applicationService.initialized else root"> {{ "COMPONENTS.EVERYWHERE.LOADING" | translate }}... </ng-container>
<ng-template #root>
  <mat-progress-bar *ngIf="applicationService.loading" mode="indeterminate"></mat-progress-bar>
  <div id="application" class="container" [ngClass]="themeName">
    <div class="row">
      <div class="col-md p-0">
        <app-header #header (openMenu)="menuComponent.show()"></app-header>

        <div
          id="content"
          class="row"
          *ngIf="initialized"
          [ngClass]="{
          'blocking': applicationService.blocked
        }"
        >
          <div id="alertContainer">
            <div class="col-md">
              <div class="message warning" *ngIf="applicationService.session.user?.status === 'PendingDeleted'">
                <p>{{"COMPONENTS.EVERYWHERE.WARNING_ACCOUNT_DISABLED" | translate}}</p>
              </div>

              <div class="message warning" *ngIf="applicationService.session.user?.incomplete()">
                <p>
                  {{"COMPONENTS.EVERYWHERE.WARNING_INCOMPLETE_ACCOUNT_START" | translate}}
                  <a class="href" routerLink="/content/profile">{{"COMPONENTS.EVERYWHERE.WARNING_INCOMPLETE_ACCOUNT_OPEN" | translate}}</a>
                  {{"COMPONENTS.EVERYWHERE.WARNING_INCOMPLETE_ACCOUNT_END" | translate}}
                </p>
              </div>
            </div>
          </div>

          <!-- //TODO ExpressionChangedAfterItHasBeenCheckedError van hier -->
          <router-outlet class="col-md" (activate)="applicationService.loader()" (deactivate)="applicationService.loading = true"></router-outlet>
        </div>
        <app-footer #footer></app-footer>
      </div>
    </div>
  </div>

  <app-menu #menuComponent [items]="menu" [@menuAnimation]="menuComponent.active ? 'visible' : 'hidden'"></app-menu>
</ng-template>
