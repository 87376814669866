import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { QuestionType } from "../classes/flow/Questionnaire/QuestionType";
import { Validation } from "../classes/flow/Questionnaire/Validation";
import { decimalValidator } from "./decimal";
import { houseNumberValidator } from "./houseNumber";
import { integerValidator } from "./integer";
import { phoneNumberValidator } from "./phoneNumber";
import { postalCodeValidator } from "./postalCode";

export const questionAnswerValidator = (questionType: QuestionType, validation?: Validation) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (questionType.prefix === "txt" || questionType.prefix === "txa") {
      switch (validation?.name) {
        case "email":
          return Validators.email(control);
        case "house_number":
          return houseNumberValidator(control);
        case "mobile":
          return phoneNumberValidator(control);
        case "integer":
          return integerValidator(control);
        case "decimal":
          return decimalValidator(control);
        case "postalcode":
          return postalCodeValidator(control);
      }
    }
    return null;
  };
};
