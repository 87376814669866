<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center">
      <h2>{{ "FORMS.NEW_COACH.TITLE" | translate }}</h2>
      <p>{{ "FORMS.NEW_COACH.DESCRIPTION" | translate }}</p>
      <br />
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_COACH.FIELDS.EMAIL" | translate }}</mat-label>
              <input matInput formControlName="email" placeholder="{{ 'FORMS.NEW_COACH.FIELDS.EMAIL' | translate }}" type="email" />
              <app-error [control]="formGroup.controls['email']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_COACH.FIELDS.FIRST_NAME" | translate }}</mat-label>
              <input matInput formControlName="firstName" placeholder="{{ 'FORMS.NEW_COACH.FIELDS.FIRST_NAME' | translate }}" type="text" />
              <app-error [control]="formGroup.controls['firstName']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_COACH.FIELDS.LAST_NAME" | translate }}</mat-label>
              <input matInput formControlName="lastName" placeholder="{{ 'FORMS.NEW_COACH.FIELDS.LAST_NAME' | translate }}" type="text" />
              <app-error [control]="formGroup.controls['lastName']"></app-error>
            </mat-form-field>
          </div>
        </div>
      </form>
      <button mat-raised-button [disabled]="!formGroup.valid" (click)="saveNewCoach()">{{ "FORMS.NEW_COACH.SAVE" | translate }}</button>
    </div>
  </div>
</div>

<ng-template #popup let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ data }}</h3>
      </div>
    </div>
  </div>
</ng-template>
