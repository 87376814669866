import { Component, Output, ViewChild, EventEmitter, AfterViewInit } from "@angular/core";
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from "ngx-scanner-qrcode";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.less"],
})
export class QrScannerComponent implements AfterViewInit {
  @ViewChild("scanner")
    scanner!: NgxScannerQrcodeComponent;

  @Output()
    qrResult: EventEmitter<string>;

  public scanResult?: string = undefined;
  public config: ScannerQRCodeConfig = {
    constraints: {
      audio: false,
      video: {
        advanced: [
          {
            facingMode: "environment",
          },
        ],
      },
    },
  };

  constructor(private readonly dialog: MatDialogRef<QrScannerComponent>) {
    this.qrResult = new EventEmitter<string>();
    this.dialog.afterClosed().subscribe(() => this.scanner.stop());
  }

  ngAfterViewInit(): void {
    this.scanner.isReady.subscribe(() => {
      const canvas = this.scanner.canvas.nativeElement;
      canvas.setAttribute("style", "width: 100%; height: 100%; background: #000000;");
      const video = this.scanner.video.nativeElement;
      video.setAttribute("style", "width: 100%; height: 100%; object-fit: cover;");
    });
  }

  saveQR(event: ScannerQRCodeResult[]) {
    if (event[0] && event[0].value !== null) {
      this.scanResult = event[0].value;
      this.scanner.stop();
      this.qrResult.emit(this.scanResult);
    }
  }

  startCamera() {
    this.scanner.start();
  }

  closeCamera() {
    if (this.scanResult) {
      this.dialog.close(this.scanResult);
    } else {
      this.dialog.close();
    }
  }
}
