import { Changes } from "../../base/Changes";
import { Entity } from "../../base/Entity";
import { Request } from "../../request/Request";
import { Role } from "../Session";
import { User, UserStatus } from "./User";

export class Retailer extends User implements Entity {
  public id?: number;
  public phoneNumber?: string;
  public postalCode?: string;
  public city?: string;
  public iBAN?: string | number;
  public houseNumber?: number;
  public houseNumberSuffix?: string;
  public registered?: boolean;
  public requests?: Request[] = [];

  public constructor({
    email,
    id,
    firstName,
    lastName,
    phoneNumber,
    postalCode,
    city,
    iBAN,
    houseNumber,
    houseNumberSuffix,
    userId,
    roles = [],
    accessStartingDate,
    accessEndDate,
    registered,
    changes
  }: {
    userId?: number;
    roles?: Role[];
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    postalCode?: string;
    city?: string;
    iBAN?: string;
    houseNumber?: number;
    houseNumberSuffix?: string;
    email: string;
    status?: UserStatus;
    id?: number;
    accessStartingDate?: Date;
    accessEndDate?: Date;
    registered?: boolean;
    changes?: Changes
  }) {
    super({ id: userId, firstName: firstName, lastName: lastName, email: email, roles: roles, accessStartingDate: accessStartingDate, accessEndDate: accessEndDate, changes: changes });
    this.phoneNumber = phoneNumber;
    this.postalCode = postalCode;
    this.city = city;
    this.iBAN = iBAN;
    this.houseNumber = houseNumber;
    this.houseNumberSuffix = houseNumberSuffix;
    this.id = id;
    this.registered = registered;
  }

  public incomplete(): boolean {
    return super.incomplete() || (this.active && !(this.phoneNumber && this.postalCode && this.houseNumber));
  }
}
