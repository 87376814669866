<div id="qr-code">
  <ngx-scanner-qrcode #scanner="scanner" (event)="saveQR($event)" [config]="config"></ngx-scanner-qrcode>
  <div class="qr-overlay prescan" *ngIf="!scanner.isStart && !scanResult">
    <div class="actions">
      <button type="button" mat-raised-button (click)="closeCamera()">Sluiten</button>
      <button type="button" mat-raised-button color="primary" (click)="startCamera()">Nu scannen</button>
    </div>
  </div>
  <div class="qr-overlay duringscan" *ngIf="scanner.isStart">
    <div class="actions">
      <button type="button" mat-raised-button (click)="closeCamera()">Sluiten</button>
    </div>
  </div>
  <div class="qr-overlay postscan" *ngIf="!scanner.isStart && scanResult">
    <h2>QR Code gescand!</h2>
    <div class="actions">
      <button type="button" mat-raised-button (click)="startCamera()">Opnieuw scannen</button>
      <button type="button" mat-raised-button color="primary" (click)="closeCamera()">Opslaan en sluiten</button>
    </div>
  </div>
</div>
