import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "../../../services/application.service";
import { ContentComponent } from "../content";

@Component({
  selector: "app-screen-retailer",
  templateUrl: "../content.html",
  styleUrls: ["../content.less"],
})
export class RetailerComponent extends ContentComponent implements OnInit, OnDestroy {
  public constructor(
    application: ApplicationService,
    route: ActivatedRoute,
    router: Router,
  ) {
    super(application, route, router);
  }

  public ngOnInit(): void {
    this.applicationService.updateTitle(ContentComponent.TITLE);
    this.applicationService.subscribe(this.id);
  }

  public ngOnDestroy(): void {
    this.applicationService.unsubscribe(this.id);
  }
}
