import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { v4 as generateUUID } from "uuid";
import { ApplicationService } from "../../../../services/application.service";
import { RetailersService } from "../../../../services/retailers.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Retailer } from "../../../../classes/flow/session/impl/Retailer";

@Component({
  selector: "app-retailers",
  templateUrl: "./retailers.component.html",
  styleUrls: ["./retailers.component.less"],
})
export class RetailersComponent implements OnInit {
  public retailers: Array<Retailer> = [];
  private id: string = generateUUID();
  public onlyActiveRetailers = true;
  public filterToggles = new FormGroup({
    statusToggle: new FormControl(false),
    registeredToggle: new FormControl(false),
  });

  public constructor(
    private readonly application: ApplicationService,
    private readonly translate: TranslateService,
    private readonly retailersService: RetailersService,
    private readonly translateService: TranslateService
  ) {}

  public async ngOnInit() {
    this.application.updateTitle(this.translateService.instant("TABLES.RETAILERS.TITLE"));
    //get dataset for table
    this.retailers = await this.retailersService.getAllRetailers();
  }

  /**
   * Translates the status of the Retailer
   * @param Retailer The Retailer that includes the status
   * @returns A translation of the status
   */
  public translateStatus: (Retailer: Retailer) => string = (Retailer) => {
    return this.translate.instant(`COMPONENTS.PROFILE.STATUSES.${Retailer.status!.toUpperCase()}`);
  };

  public makeDashboardLink(linkId: string, linkRole: string): string {
    return "/content/coordinator/dashboard/" + linkId + "_" + linkRole;
  }
  /**
   * Displays roles of the Retailer
   * @param Retailer The Retailer that includes the roles
   * @returns A string with all roles of the Retailer
   */
  public displayRoles: (Retailer: Retailer) => string = (Retailer) => {
    if (!Retailer.statistics) return "-";
    const roles: string[] = Retailer.statistics.map((statistic) => {
      return (
        this.translate.instant("COMPONENTS.PROFILE.ROLES." + statistic.role.name.toUpperCase()) +
        (statistic.requestCount != undefined ? " (" + statistic.requestCount + ")" : "")
      );
    });
    return roles.slice(0, -1).join(", ") + " " + this.translate.instant("GENERAL.ADDITIVE_CONJUNCTION") + " " + roles[roles.length - 1];
  };

  public displayRegister: (Retailer: Retailer) => string = (Retailer) => {
    if (Retailer.registered == true) {
      return this.translate.instant("COMPONENTS.PROFILE.REGISTER.REGISTERED");
    } else {
      return this.translate.instant("COMPONENTS.PROFILE.REGISTER.UNREGISTERED");
    }
  };

  /**
   * Uses the AccessDates to determine which information to display
   * @param Retailer The Retailer that includes the dates to aquire the status
   * @returns "Active" - "Activates on: date" - "Expired on: date" - "New"
   */
  public displayStatus: (Retailer: Retailer) => string = (Retailer) => {
    switch (Retailer.status) {
      case "Active": {
        return this.translate.instant("COMPONENTS.PROFILE.STATUSES.ACTIVE");
      }
      case "New": {
        return this.translate.instant("COMPONENTS.PROFILE.STATUSES.NEW");
      }
      case "PendingDeleted": {
        return this.translate.instant("TABLES.RETAILERS.STATUS_WILL_BE_EXPIRED") + Retailer.accessEndDate?.toLocaleDateString();
      }
      case "Deleted": {
        return this.translate.instant("TABLES.RETAILERS.STATUS_EXPIRED") + Retailer.accessEndDate?.toLocaleDateString();
      }
    }
  };

  public OnDestroy(): void {
    this.application.unsubscribe(this.id);
  }

  /**
   * Makes a filter function depending on toggles
   * @returns a function that can be used as a filter in material table component
   */
  public getFilterFunction = () => {
    const customTableFilter: (data: unknown, filter: string) => boolean = (data, filter) => {
      let activeCheck = false;
      let searchCheck = false;
      let registeredCheck = false;
      if (data instanceof Retailer) {
        activeCheck = this.filterToggles.value["statusToggle"] ? true : data?.status?.toLowerCase() === "active";
        registeredCheck = this.filterToggles.value["registeredToggle"] ? true : data?.registered === true;

        if (filter === "emptyValueCheck") {
          return activeCheck && registeredCheck;
        }

        searchCheck = data?.email?.toLowerCase().includes(filter) ? true : false;
      }
      return activeCheck && searchCheck && registeredCheck;
    };
    return customTableFilter;
  };
}
