import { Injectable } from "@angular/core";
import { MutationResult } from "apollo-angular";
import { Questionnaire } from "../classes/flow/Questionnaire/Questionnaire";
import { Question } from "../classes/flow/Questionnaire/Question";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  public constructor(private graphqlService: GraphQLService) {}

  /**
   * Gets all questions and possible answers for an energy consult
   * @param energyConsultId The id of the energy consult
   * @returns Promise<MutationResult<any>>
   */
  public async getAllQuestionsAndAnswers(energyConsultId: number): Promise<Questionnaire[]> {
    const res = await this.graphqlService.query(this.getQueryForAllQuestionsAndPossibleAnswers(energyConsultId));
    return this.markupQuestionnaires(this.createQuestionnaires(res));
  }

  /**
   * Gets all questions and answers for an energy consult that is filled in
   * @param energyConsultId The id of the energy consult
   * @returns Promise<MutationResult<any>>
   */
  public async getAllAnsweredQuestions(energyConsultId: number): Promise<Questionnaire[]> {
    const res = await this.graphqlService.query(this.getQueryForAllQuestionsAndAnswers(energyConsultId));
    return this.getAllQuestionnairesWithAnswers(this.createQuestionnaires(res));
  }

  private getAllQuestionnairesWithAnswers(questionnaires: Questionnaire[]) {
    questionnaires.forEach((questionnaire) => (questionnaire.questions = this.getAllQuestionsWithAnswers(questionnaire.questions ?? [])));
    return questionnaires.filter((questionnaire) => this.getAllQuestionsWithAnswers(questionnaire.questions ?? []).length > 0);
  }

  private getAllQuestionsWithAnswers(questions: Question[]): Question[] {
    questions = questions.filter((question) => question.answer);
    questions.forEach((question) => (question.followUpQuestions = this.getAllQuestionsWithAnswers(question.followUpQuestions)));
    return questions;
  }

  /**
   * Filters and sorts all questionaires
   * @param questionnaires All questionnaires
   * @returns A filtered and sorted list of questionnaires
   */
  private markupQuestionnaires(questionnaires: Questionnaire[]): Questionnaire[] {
    questionnaires = this.getActiveQuestionnaires(questionnaires);
    return this.SortQuestionnairesByQuestionnaireType(questionnaires);
  }

  /**
   * Sorts questionnaires by order of questionnaire types
   * @param questionnaires All questionnaires
   * @returns A sorted list of questionnaires
   */
  private SortQuestionnairesByQuestionnaireType(questionnaires: Questionnaire[]): Questionnaire[] {
    return questionnaires.sort((first, second) => first.questionnaireType.order - second.questionnaireType.order);
  }

  /**
   * Gets all active questionnaires
   * @param questionnaires All questionnaires
   * @returns All active questionnaires
   */
  private getActiveQuestionnaires(questionnaires: Questionnaire[]): Questionnaire[] {
    return questionnaires.filter((questionnaire) => questionnaire.active);
  }

  /**
   * Creates questionnaires based on a query result
   * @param queryResult The data of the questionnaires
   * @returns All created questionnaires
   */
  private createQuestionnaires(queryResult: MutationResult<any>): Questionnaire[] {
    return queryResult.data.energyConsults.value?.[0]?.specialty?.specialtyQuestionnaires?.map((questionnaire: any) => {
      questionnaire = questionnaire.questionnaire;
      return {
        id: questionnaire.id,
        title: questionnaire.title,
        description: questionnaire.description,
        active: questionnaire.active,
        questionnaireType: {
          id: questionnaire.questionnaireType.id,
          name: questionnaire.questionnaireType.name,
          order: questionnaire.questionnaireType.order,
        },
        questions: questionnaire.questions.map((question: any) => {
          return {
            id: question.id,
            active: question.active,
            name: question.name,
            description: question.description,
            placeholder: question.placeholder,
            required: question.required,
            questionType: {
              id: question.questionType.id,
              name: question.questionType.name,
              prefix: question.questionType.prefix,
            },
            options: !question.questionOptions
              ? undefined
              : question.questionOptions.map((option: any) => {
                return {
                  id: option.id,
                  value: option.value,
                };
              }),
            validation: !question.validation
              ? undefined
              : {
                id: question.validation.id,
                name: question.validation.name,
              },
            followUpQuestions: [],
            logic: !question.parentQuestionId
              ? undefined
              : {
                compareOptionId: question.compareOptionId,
                compareAnswer: question.compareAnswer,
                operatorType: !question.operatorType
                  ? undefined
                  : {
                    id: question.operatorType.id,
                    name: question.operatorType.name,
                    operation: question.operatorType.operation,
                  },
                parentQuestionId: question.parentQuestionId,
              },
            answer:
              question.questionAnswers.length == 0
                ? undefined
                : {
                  id: question.questionAnswers[0].id,
                  answer: question.questionAnswers[0].answer,
                  questionId: question.questionAnswers[0].questionId,
                  energyConsultId: question.questionAnswers[0].energyConsultId,
                  changes: this.graphqlService.createChangesObject(question.questionAnswers[0].changes),
                },
          } as Question;
        }),
      } as Questionnaire;
    });
  }

  /**
   * Returns the query for getting all questions and answers if the questions have already been answered.
   */
  private getQueryForAllQuestionsAndAnswers(energyConsultId: number): string {
    return `
    query {
      energyConsults {
        value(where: {id: {eq: ${energyConsultId}}}) {
          specialty {
            specialtyQuestionnaires {
              questionnaire {
                id
                title
                description
                active
                questionnaireType {
                  id
                  name
                  order
                }
                questions {
                  id
                  name
                  description
                  active
                  placeholder
                  required
                  questionType {
                    id
                    name
                    prefix
                  }
                  parentQuestionId
                  compareOptionId
                  compareAnswer
                  operatorType {
                    id
                    name
                    operation
                  }
                  questionOptions {
                    id
                    value
                  }
                  questionAnswers(where: {energyConsultId: {eq: ${energyConsultId}}}) {
                    id
                    answer
                    energyConsultId
                    questionId
                  }
                  questionOptions {
                    id
                    value
                  }
                  validation {
                    id
                    name
                  }
                }
              }
            }
          }
          changes {
            fullDetails {
              key
              value {
                userId
                time
              }
            }
            lastChange {
              userId
              time
            }
          }
        }
        messages{
          message
        }
      }
    }`;
  }

  /**
   * Returns the query for getting all questions and possible answers
   */
  private getQueryForAllQuestionsAndPossibleAnswers(energyConsultId: number): string {
    return `
    query {
      energyConsults {
        value(where: {id: {eq: ${energyConsultId}}}) {
          specialty {
            specialtyQuestionnaires {
              questionnaire {
                id
                title
                description
                active
                questionnaireType {
                  id
                  name
                  order
                }
                questions(where: {active: {eq: true}}) {
                  id
                  name
                  description
                  active
                  placeholder
                  required
                  questionType {
                    id
                    name
                    prefix
                  }
                  parentQuestionId
                  compareOptionId
                  compareAnswer
                  operatorType {
                    id
                    name
                    operation
                  }
                  questionOptions {
                    id
                    value
                  }
                  questionAnswers(where: {energyConsultId: {eq: ${energyConsultId}}}) {
                    id
                    answer
                    energyConsultId
                    questionId
                    changes {
                      lastChange {
                        userId
                        time
                      }
                      fullDetails {
                        key
                        value {
                          userId
                          time
                        }
                      }
                    }
                  }
                  questionOptions {
                    id
                    value
                  }
                  validation {
                    id
                    name
                  }
                }
              }
            }
          }
          changes {
            fullDetails {
              key
              value {
                userId
                time
              }
            }
            lastChange {
              userId
              time
            }
          }
        }
        messages{
          message
        }
      }
    }`;
  }

  public async getPdfFile(requestId: number): Promise<string> {
    const res = await this.graphqlService.query(`
      query {
        getReportAsPDF (energyConsultId: ${requestId}) {
          value {
            content
          }
          messages {
            message
          }
        }
      }`);

    return res.data["getReportAsPDF"]?.value?.content;
  }
}
