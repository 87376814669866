import { Changes } from "../../base/Changes";
import { Specialty } from "../../Questionnaire/Specialty";
import { Role } from "../Session";
import { User } from "./User";

export class Coach extends User {
  public id?: number;
  public emailSubscription?: boolean;
  public specialties: Specialty[] = [];
  public registered?: boolean;

  public constructor({
    id,
    roles = [],
    firstName,
    lastName,
    userEmail,
    userId,
    accessStartingDate,
    accessEndDate,
    registered,
    emailSubscription,
    changes,
  }: {
    id?: number;
    userEmail: string;
    userId?: number;
    roles?: Role[];
    firstName?: string;
    lastName?: string;
    accessStartingDate?: Date;
    accessEndDate?: Date;
    registered?: boolean;
    emailSubscription?: boolean;
    changes?: Changes;
  }) {
    super({
      id: userId,
      roles: roles,
      firstName: firstName,
      lastName: lastName,
      email: userEmail,
      accessStartingDate: accessStartingDate,
      accessEndDate: accessEndDate,
      changes: changes,
    });
    this.id = id;
    this.emailSubscription = emailSubscription;
    this.registered = registered;
  }

  /**
   * Returns the full name of the coach
   * @returns
   */
  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
