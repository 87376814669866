import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../services/application.service";
import { UserService } from "../../services/user.service";
import { v4 as generateUUID } from "uuid";
import Logger from "../../classes/Logger";
import { Role } from "../../classes/flow/session/Session";
import { getLanguage } from "../../helpers/determineLanguage";

@Component({
  selector: "app-choose-role",
  templateUrl: "./choose-role.component.html",
  styleUrls: ["./choose-role.component.less"],
})
export class ChooseRoleComponent implements OnInit, OnDestroy {
  private id: string = generateUUID();
  public get roles(): Role[] {
    return this.application.session.user?.roles ?? [];
  }

  public constructor(private readonly application: ApplicationService, private readonly router: Router, private readonly userService: UserService) {}

  public async ngOnInit() {
    const selectedRole = this.application.session.activeRole;
    const path = history.state.path;

    this.application.updateTitle("Selecteer rol ");
    this.application.subscribe(this.id);

    try {
      if ((path && this.roles.includes(selectedRole)) || this.roles.length == 1) {
        await this.activateRole(selectedRole || this.roles[0]);
        this.router.navigate([path]);
      }
    } catch (error) {
      Logger.error(this.application.getErrorMessage(error));
    }
  }

  public ngOnDestroy(): void {
    this.application.unsubscribe(this.id);
  }

  public async activateRole(role: Role) {
    await this.application.session.setActiveRole(role);
    this.application.session.user = await this.userService.getUserByRole(role, this.application.session.user!);
    this.userService.registerLanguageForUser(this.application.session.user, getLanguage());
    this.application.initMenu();
    this.router.navigate([history.state.path || "content"]);
  }
}
