import { Component, Input } from "@angular/core";

@Component({
  selector: "app-async-action-button[eventHandlerFunction]",
  templateUrl: "./async-action-button.component.html",
  styleUrls: ["./async-action-button.component.less"],
})
export class AsyncActionButtonComponent {
  @Input()
  public condition?: boolean;
  @Input()
  public eventHandlerFunction!: () => Promise<void>;
  private isExecuting = false;

  public async executableFunction(): Promise<void> {
    if(!this.isExecuting) {
      this.isExecuting = true;
      await this.eventHandlerFunction();
      this.isExecuting = false;
    }
  }
}
