import { AfterContentInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";
import { FilterGroup } from "../../../../components/badge-filters/badge-filters";
import { ApplicationService } from "../../../../services/application.service";
import { EnergyConsultService } from "../../../../services/energy-consult.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { UserService } from "../../../../services/user.service";
import { UtilitiesService } from "../../../../services/utilities.service";
import { GraphQLService } from "../../../../services/graphql.service";
import { DialogService } from "../../../../services/dialog.service";
import { RequestStates } from "../../../../classes/flow/request/RequestStates";

@Component({
  selector: "app-screen-dashboard",
  templateUrl: "./dashboard.html",
  styleUrls: ["./dashboard.less"],
})
export class DashboardComponent implements OnInit, AfterContentInit {
  @ViewChild("requestTable") requestTable: any;

  protected static readonly TITLE = "Dashboard ";

  public submitRequest = false;
  public filters: FilterGroup<EnergyConsult>[] = [];
  public filteredRequests: EnergyConsult[] = [];
  public splittedRequests: EnergyConsult[][][] = [];
  public requests: EnergyConsult[] = [];
  public currentSortMethod!: string;
  public sortAscending!: boolean;
  public viewTileMode = true;

  public constructor(
    public readonly applicationService: ApplicationService,
    private readonly requestService: EnergyConsultService,
    private readonly route: ActivatedRoute,
    public readonly userService: UserService,
    private readonly utilityService: UtilitiesService,
    private readonly translateService: TranslateService,
    private readonly snackService: SnackbarService,
    private readonly graphQLService: GraphQLService,
    private readonly dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.applicationService.updateTitle(DashboardComponent.TITLE);
    this.viewTileMode = localStorage.getItem("viewTileMode") && localStorage.getItem("viewTileMode") === "false" ? false : true;
  }

  async ngAfterContentInit() {
    const id = this.route.snapshot.paramMap.get("id") ?? "";
    const data = id ? id.split("_") : [];

    data.length > 0 ? await this.initRequests(data[0], data[1]) : await this.initRequests("", "");
    this.filteredRequests = this.requests;
    this.initFilter();
    this.submitRequest = this.applicationService.session.activeRole.name === "resident";
    this.sortRequests("lastStateChangeDate");
  }

  /**
   * Initializes filter per role
   */
  private initFilter() {
    const stateFilters = this.utilityService.initStateFilter(this.requests);
    this.filters = [{ name: "state", filters: stateFilters, activeFilters: [], description: this.translateService.instant("COMPONENTS.DASHBOARD.FILTERS.STATE") }];

    if (this.applicationService.session.activeRole.name === "coach") {
      const specialtyFilters = this.utilityService.initSpecialtyFilter(this.requests);
      this.filters.push({
        name: "specialty",
        filters: specialtyFilters,
        activeFilters: [],
        description: this.translateService.instant("COMPONENTS.DASHBOARD.FILTERS.SPECIALTY"),
      });
    }

    //set Active Filters
    if (this.filters.length > 1) {
      this.filters[0] = this.utilityService.getSavedFiltersFromStorage("state", this.filters[0]);
      this.filters[1] = this.utilityService.getSavedFiltersFromStorage("specialty", this.filters[1]);
    } else {
      this.filters[0] = this.utilityService.getSavedFiltersFromStorage("state", this.filters[0]);
    }
    this.filterRequests();
  }

  /**
   * Filters the requests
   */
  public filterRequests() {
    this.filteredRequests = this.utilityService.filterRequests(this.filters, this.requests);
    this.splitRequests();
    if (this.filters.length > 1) {
      this.utilityService.setSavedFiltersInStorage("state", this.filters[0]);
      this.utilityService.setSavedFiltersInStorage("specialty", this.filters[1]);
    } else {
      this.utilityService.setSavedFiltersInStorage("state", this.filters[0]);
    }
  }

  /**
   * checks the value of the input field and then filters filteredRequest
   * @param field has the value of the input field
   */
  public inputFilter(field: string) {
    this.filterRequests();
    this.filteredRequests = this.filteredRequests.filter((econsult) => {
      if (
        (econsult.coach?.fullName.toLowerCase().includes(field.toLowerCase()) && econsult.coach?.fullName.length >= field.length) ||
        (econsult.postalCode.toLowerCase().includes(field.toLowerCase()) && econsult.postalCode.length >= field.length) ||
        (econsult.message.toLowerCase().includes(field.toLowerCase()) && econsult.message.length >= field.length) ||
        field.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    });
    this.splitRequests();
    if (this.requestTable) {
      this.requestTable.reload();
    }
  }

  /**
   * Splits the requests into smaller arrays for the compact view
   */
  public splitRequests() {
    //get states from available requests -> sort them in chronologic order -> see enum type
    let states = [...new Set(this.filteredRequests.map((request) => request.state.name))];
    const statesOrder = Object.values(RequestStates);
    states = states.sort((a, b) => statesOrder.indexOf(a) - statesOrder.indexOf(b));

    // split requests into multiple arrays by state -> then per array do ->
    this.splittedRequests = states.map((state) => {
      const requestsByState: EnergyConsult[] = this.filteredRequests
        .filter((request) => request.state.name === state)
        .map((request) => {
          return request;
        });

      //get specialties that are present in the requests -> sort them by order
      const specialties = [...new Map(requestsByState.map((request) => [request.specialty.name, request.specialty])).values()];
      specialties.sort((a, b) => <number>a.order - <number>b.order);

      //returns per specialty array with the requests from that specialty
      return specialties.map((specialty) => {
        return requestsByState
          .filter((request) => request.specialty.name === specialty.name)
          .map((request) => {
            return request;
          });
      });
    });
  }

  public sortRequests(sortMethod: string) {
    if (sortMethod !== this.currentSortMethod) {
      this.sortAscending = true;
    }
    this.currentSortMethod = sortMethod;
    this.utilityService.sortRequestsBy(this.currentSortMethod, this.sortAscending, this.filteredRequests);
    if (this.sortAscending) {
      this.sortAscending = false;
    } else {
      this.sortAscending = true;
    }

    if (this.requestTable) {
      this.requestTable.reload();
    }
    this.splitRequests();
  }

  /**
   * Return requests depending on states
   * @returns
   */
  public async initRequests(id: string, role: string) {
    const requests = (id === "" ? await this.requestService.load() : await this.requestService.loadInspectingAsCoord(id, role)) || [];
    this.requests = requests ? (this.utilityService.filterRequestsByState(requests) as EnergyConsult[]) : [];
    this.filteredRequests = this.requests;
  }

  /**
   *
   * @param requests
   * @returns total amount of new requests
   */
  public getNewRequest(requests: EnergyConsult[]) {
    const totalNewRequests = requests.filter((request) => request.state.name === RequestStates.NEW);
    return totalNewRequests;
  }
  public getNewRequestByType() {
    const requests = this.getNewRequest(this.requests);
    const requestCountPerSpecialty = new Map();

    requests.forEach((request) => {
      const count = requestCountPerSpecialty.get(request.specialty.name) || 0;
      requestCountPerSpecialty.set(request.specialty.name, count + 1);
    });
    const array = Array.from(requestCountPerSpecialty, ([name, value]) => ({ name, value }));
    return array;
  }
  public async coachReminder() {
    // eslint-disable-next-line quotes
    const data = JSON.stringify(this.getNewRequestByType()).replace(/"/g, '\\"');
    const res = await this.graphQLService.query(`mutation sendReminderMail {
      send_reminder_email(input: {
        NewRequestCountArray: "${data}"
      }) {
        Message
      }
    }`);
    if (res.data["send_report_as_pdf"]?.Message) {
      throw new Error("Something went wrong");
    } else {
      this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.MAIL"));
    }
  }

  public openDialog(dialog: TemplateRef<unknown>) {
    this.dialogService.open({
      template: dialog,
    });
  }

  public setViewMode(state: boolean) {
    this.viewTileMode = state;
    localStorage.setItem("viewTileMode", this.viewTileMode.toString());
  }

  public displayState: (request: EnergyConsult) => string = (request) => {
    return this.translateService.instant("COMPONENTS.TILE.STATUSES." + request.state.name);
  };

  public displaySpecialty: (request: EnergyConsult) => string = (request) => {
    return request.specialty.name;
  };

  public displayCoach: (request: EnergyConsult) => string = (request) => {
    return request.coach ? request.coach.firstName + " " + request.coach.lastName : this.translateService.instant("COMPONENTS.TILE.ABSENT_DATA");
  };

  public displayDate: (request: EnergyConsult) => string = (request) => {
    return request.requestDate ? request.requestDate.toLocaleDateString() : this.translateService.instant("COMPONENTS.TILE.ABSENT_DATA");
  };

  public displayAppointment: (request: EnergyConsult) => string = (request) => {
    return request.appointmentDate ? request.appointmentDate.toLocaleDateString() : this.translateService.instant("COMPONENTS.TILE.ABSENT_DATA");
  };

  public displayLocation: (request: EnergyConsult) => string = (request) => {
    return request.requestDate ? request.requestDate.toLocaleDateString() : this.translateService.instant("COMPONENTS.TILE.ABSENT_DATA");
  };
}
