import { AfterViewInit, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ReplacementService } from "../../../../services/replacement.service";
import { MessageService } from "../../../../services/message.service";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { timeValidator } from "../../../../validators/time";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";

@Component({
  selector: "app-new-replacementclaim-existing-resident",
  templateUrl: "./new-replacementclaim-existing-resident.component.html",
  styleUrls: ["./new-replacementclaim-existing-resident.component.less"],
})
export class NewReplacementclaimExistingResidentComponent implements AfterViewInit {
  public currentDate: Date = new Date();
  public maxDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 2));

  public energyConsult?: EnergyConsult;
  public formGroup = new FormGroup({
    extraMessage: new FormControl("", Validators.required),
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl("", [Validators.required, timeValidator]),
  });

  constructor(
    private readonly replacementService: ReplacementService,
    private readonly messageService: MessageService,
    private _location: Location,
    private route: ActivatedRoute
  ) {}

  public ngAfterViewInit(): void {
    this.initializeFormGroup();
  }

  /**
   * calls the function after all fields have been filled in and gets the resident ID.
   * using the getGivenDate function returns the proper date format and calls a mutation query
   */
  public async saveNewClaim() {
    const date: Date = this.getGivenDate();
    const id = await this.replacementService.getResident(this.route.snapshot.params.id);
    const result = await this.replacementService.addReplacement(id, date.toISOString(), this.formGroup.value.extraMessage!);

    this.messageService.handle(result.messages, "/content/dashboard");
    if (result) {
      this._location.back();
    }
  }

  /**
   * gets the filled in date
   * @returns  the date and time in one variable
   */
  private getGivenDate(): Date {
    const date: Date = this.formGroup.value.date!;
    date.setHours(+this.formGroup.value.time!.substr(0, 2), +this.formGroup.value.time!.substr(3, 2));
    return date;
  }

  //after running the code makes the formgroup return the proper valeus
  private initializeFormGroup() {
    const appointmentDate = this.energyConsult?.appointmentDate;
    const today = new Date();
    this.formGroup.patchValue({
      date: appointmentDate && appointmentDate < today ? today : appointmentDate,
      time: appointmentDate?.toTimeString().substring(0, 5) ?? "",
    });
  }
}
