import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, Router } from "@angular/router";
import { ApplicationService } from "../services/application.service";

@Injectable({ providedIn: "root" })
export class IsActiveGuard implements CanActivate, CanActivateChild {
  constructor(private readonly router: Router, private readonly applicationService: ApplicationService) {}

  public async canActivate() {
    return await this.checkForActivity();
  }

  public async canActivateChild() {
    return await this.checkForActivity();
  }

  private async checkForActivity() {
    await this.applicationService.initialize();
    if (!this.applicationService.session.user?.isDeleted()) {
      return true;
    }

    await this.router.navigate(["content/profile"]);
    return false;
  }
}
