import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Entity } from "../../classes/flow/base/Entity";
import { QuestionnaireType } from "../../classes/flow/Questionnaire/QuestionnaireType";
import { Specialty } from "../../classes/flow/Questionnaire/Specialty";
import { compareEntities } from "../../helpers/compareObjects";
import { QuestionnaireTypeService } from "../../services/questionnaire-type.service";
import { SpecialtyService } from "../../services/specialty.service";

@Component({
  selector: "app-questionnaire-form[formGroup]",
  templateUrl: "./questionnaire-form.component.html",
  styleUrls: ["./questionnaire-form.component.less"],
})
/**
 * A component for basic details of a questionnaire.
 * The specified formGroup should contain a:
 * - title
 * - description
 * - specialties collection
 */
export class QuestionnaireFormComponent implements OnInit {
  @Input("formGroup")
  public formGroup!: UntypedFormGroup;

  public allSpecialties?: Specialty[];
  public allQuestionnaireTypes?: QuestionnaireType[];

  constructor(private specialtyService: SpecialtyService, private questionnaireTypeService: QuestionnaireTypeService) {}

  ngOnInit() {
    this.initPredefinedData();
  }

  /**
   * Sets all the required form data
   */
  private async initPredefinedData() {
    this.allQuestionnaireTypes = await this.questionnaireTypeService.getQuestionnaireTypes();
    this.allSpecialties = await this.specialtyService.getSpecialties();
  }

  /**
   * Compares two entities
   * @param firstEntity The first entity
   * @param secondEntity The second entity
   * @returns True if the entities are equal, false otherwise
   */
  public compareEntities(firstEntity: Entity, secondEntity: Entity)
  {
    return compareEntities(firstEntity, secondEntity);
  }
}
