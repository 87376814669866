<div id="headerContainer" class="container">
  <div class="row">
    <div class="col-md">
      <div class="row">
        <div class="col col-md-5 justify-content-start d-flex">
          <button mat-flat-button (click)="openMenu.emit('menu')">
            <mat-icon>menu</mat-icon>
            <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.MENU" | translate }}</span>
          </button>
        </div>

      <div id="imageContainer" class="col col-md-2 align-items-center img-fluid justify-content-center " routerLink="/">
        <img src="assets/organisation/images/logo.png" />
      </div>
  
      <div class="col col-md-5 justify-content-end d-flex">
        <button class="bg-transparent" mat-flat-button routerLink="/faq">
          <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.HELP" | translate }}</span>
          <mat-icon>help</mat-icon>
        </button>
  
        <ng-container *ngIf="applicationService.session.authenticated; else login">
          <button mat-flat-button (click)="logout()">
            <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.LOG_OUT" | translate }}</span>
            <mat-icon>logout</mat-icon>
          </button>
        </ng-container>
  
        <ng-template #login>
          <button mat-flat-button [routerLink]="'content'">
            <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.LOGIN" | translate }}</span>
            <mat-icon>login</mat-icon>
          </button>
        </ng-template>
      </div>
      </div>

  </div>
  </div>
</div>
