import { Message, MessageType } from "../classes/system/Message";
import { Injectable } from "@angular/core";
import { ApplicationService } from "./application.service";
import { Location } from "@angular/common";
import { SnackbarService } from "./snackbar.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly applicationService: ApplicationService,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService
  ) { }

  public handle(messages: Message[], redirect: string) {
    if(this.block(messages)) {
      this.applicationService.blocked = true;
    } else if(this.redirect(messages)) {
      this.router.navigate([redirect]);
    } else if(this.refresh(messages)) {
      location.reload();
    } else if(this.goBack(messages)) {
      this.location.back();
    } else {
      this.showMessages(messages);
    }
  }

  private redirect(messages: Message[]): boolean {
    return messages.some(message => message.redirect);
  }

  private refresh(messages: Message[]): boolean {
    return messages.some(message => message.refresh);
  }

  private block(messages: Message[]): boolean {
    return messages.some(message => message.block);
  }

  private goBack(messages: Message[]): boolean {
    return messages.some(message => message.back);
  }

  private showMessages(messages: Message[]) {
    // Show the messages by prioritizing the level
    messages = [...messages];
    const order: MessageType[] = ["error", "warning", "success", "info"];
    messages.sort((a, b) => {
      for (const type of order) {
        if(a.type === type) {
          return -1;
        } else if(b.type === type) {
          return 1;
        }
      }
      return 0;
    });

    const messageToShow = messages[0];
    this.snackbarService.open(messageToShow.message, this.translateService.instant("COMPONENTS.EVERYWHERE.MESSAGE_TYPE." + messageToShow.type));
  }
}