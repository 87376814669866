<div id="allUserContainer">
  <div class="row">
    <div class="col-md text-center">
      <h2>{{"COMPONENTS.TILE.RETAILERS_TITLE" | translate }}</h2>
    </div>
</div>
<div class="col-md text-center">
  <button class="retailerButton"  mat-raised-button  [routerLink]="['/content/coordinator/new-retailer']">{{ "COMPONENTS.RETAILERS.ADD_RETAILER_BUTTON" | translate }}</button>
</div>
<div class="d-flex justify-content-around mt-4" [formGroup]="filterToggles">
  <mat-slide-toggle formControlName="statusToggle" (change)="retailersTable.update()">{{ "COMPONENTS.USERS.STATUS_TOGGLE" | translate }}</mat-slide-toggle>
  <mat-slide-toggle formControlName="registeredToggle" (change)="retailersTable.update()">{{ "COMPONENTS.USERS.REGISTER_TOGGLE" | translate }}</mat-slide-toggle>
</div>
<app-table class="text-center"
#retailersTable
id="retailers"
[data]="retailers"
[search]="true"
[pageSizes]="[5, 10, 25]"
[customFilter]="getFilterFunction()"
[headers]="[
  { visualName: 'EMAIL', mappedTo: 'email' },
  { visualName: 'STATUS', resolver: displayStatus },
  { visualName: 'REGISTER', resolver: displayRegister },
  { visualName: 'OPEN', link: { baseUrl: '/content/profile', params: ['userId', 'retailers'] } }
]"
></app-table>