export const APP_ORGANISATION = {
  ORGANISATION: "dev",
  API_URL: "https://dev.api.epf.bespaartenergie.net/",
  CLIENT_ID: "068be95c-8e56-4cdb-8be0-5347038603f5",
  AUTHORITY: "https://bespaartenergiedev.b2clogin.com/bespaartenergiedev.onmicrosoft.com/B2C_1_signupandsignin",
  KNOWN_AUTHORITY: "https://bespaartenergiedev.b2clogin.com",

  THEME_NAME: "emmen",

  SOCIALS: {
    SLOGAN: "Dev bespaart energie",
    LINKS: {
      MAIN: "https://www.google.com/",
      PRIVACY: "https://www.google.com/",
      DISCLAIMER: "https://www.facebook.com/",
    },
  },
};
