import { HttpClient } from "@angular/common/http";
import { Message } from "../classes/system/Message";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { MutationResult } from "apollo-angular";
import { firstValueFrom } from "rxjs";
import { APP_ORGANISATION } from "../../environments/environment.organisation";
import { Entity } from "../classes/flow/base/Entity";
import { Api, ApiService } from "./api.service";
import { Changes } from "../classes/flow/base/Changes";

@Injectable({
  providedIn: "root",
})
export class GraphQLService {
  public constructor(public readonly msalService: MsalService, private readonly apiService: ApiService, private http: HttpClient) {
    this.apiService.apiList.set("gql", new Api(http, APP_ORGANISATION.API_URL));
  }

  /**
   * Parses an object to fit in the mutation
   * @param object
   * @returns
   */
  public parseObject(object: unknown): unknown {
    return JSON.stringify(object).replace(/"([^"]+)":/g, "$1:");
  }

  /**
   * Send the query to the server and returns the result of it
   * @param {string} query - The query to execute and get the result from
   * @return {MutationResult} - The result of the query
   */
  public async query(query: string, notPrivate = false): Promise<MutationResult<any>> {
    const res = (await firstValueFrom(
      this.apiService.getApi("gql").post(notPrivate ? "graphqlpublic/" : "graphql/", {
        query: query,
      })
    )) as MutationResult<any>;

    if (res.errors) {
      for (const error of res.errors) {
        throw error.message;
      }
    }
    return res;
  }

  /**
   * Removes the object 'key' string quotes
   * @param obj The object to remove the 'key' quotes from
   * @returns A JSON string representing the given object
   */
  public hasuraArrayObjectCleaner(obj: unknown) {
    return JSON.stringify(obj, this.replaceMap, 2).replace(/("[A-Za-z\d]+":)/g, (match) => {
      return match.replace(/"/g, "");
    });
  }

  /**
   * Formats the changes object of an entity
   * @param entity the entity that includes the changes object
   * @returns A formatted string for the changes object
   */
  public formatChangesObject(entity: Entity) {
    return `{
      fullDetails: ${this.hasuraArrayObjectCleaner(entity.changes?.fullDetails)}
    }`;
  }

  /**
   * If the value is a map, it will be converted a map to an array of objects, in order to stringify them
   * @param value The value to be replaced
   * @returns If the value is a map, it will return an array of stringified key-value pairs, otherwise the given value
   */
  public replaceMap(key: string, value: any): any {
    if (value instanceof Map) {
      return [...value.entries()].map(([key, value]) => {
        return { key, value };
      });
    } else {
      return value;
    }
  }

  /**
   * Creates a Changes object for a entity based on the value of the "changes" resolver from the query of mutation
   * @param graphQLChanges The value of the "changes" resolver from the query of mutation
   * @returns The Changes returned by GraphQL
   */
  public createChangesObject(graphQLChanges: any): Changes {
    return {
      fullDetails: new Map(
        graphQLChanges?.fullDetails.map((change: any) => {
          return [change.key, { userId: change.value.userId, time: change.value.time ? new Date(change.value.time) : undefined }];
        })
      ),
      lastChange: { userId: graphQLChanges?.lastChange.userId, time: graphQLChanges?.lastChange.time ? new Date(graphQLChanges?.lastChange.time) : undefined },
    };
  }

  /**
   * Converts a list of json messages to a collection of actual messages
   * @param json A list of messages from graphql
   * @returns The collection of Messages constructed from the graphql
   */
  public constructMessages(json: [{ [key: string]: any }]): Message[] {
    return json.map((message) => this.constructMessage(message));
  }

  /**
   * Converts a json message to an actual message
   * @param json A message from graphql
   * @returns The Message constructed from the graphql
   */
  private constructMessage(json: { [key: string]: any }): Message {
    return {
      number: json.number,
      undo: json.undo,
      refresh: json.refresh,
      block: json.block,
      back: json.back,
      redirect: json.GoToLink,
      linkToGoTo: json.GoToLink ? json.linkToGoTo : null,
      lc: json.lc,
      message: json.message,
      type: json.type,
    };
  }
}
