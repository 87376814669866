import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from "@azure/msal-browser";
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from "@azure/msal-angular";
import { APP_ORGANISATION } from "../../environments/environment.organisation";

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(`${logLevel}: ${message}`);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: APP_ORGANISATION.CLIENT_ID,
      authority: APP_ORGANISATION.AUTHORITY,
      knownAuthorities: [APP_ORGANISATION.KNOWN_AUTHORITY],
      redirectUri: "/",
      postLogoutRedirectUri: "/loggedout",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  console.log(APP_ORGANISATION.API_URL);

  protectedResourceMap.set(APP_ORGANISATION.API_URL + "graphqlpublic/", null);
  protectedResourceMap.set(`${APP_ORGANISATION.API_URL}graphql/`, [APP_ORGANISATION.CLIENT_ID]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [""],
    },
    loginFailedRoute: "/login-failed",
  };
}
