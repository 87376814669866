import { Changes } from "../base/Changes";
import { Entity } from "../base/Entity";
import { RequestState, RequestStates } from "./RequestStates";

export abstract class Request implements Entity {
  public constructor(public id: number, public requestDate: Date, public state: RequestState, public lastStateChangeDate?: Date, public changes?: Changes) {}

  /**
   * Checks if the request can be changed.
   * @returns True if it has a date or it is picked, false otherwise.
   */
  public get canBeChanged(): boolean {
    return this.state.name == RequestStates.PICKED || this.state.name == RequestStates.DATE;
  }

  /**
   * Checks if the request can be claimed.
   * @returns True if this request can be claimed, false otherwise.
   */
  public get canBeClaimed(): boolean {
    return !(this.state.name == RequestStates.CANCELED || this.state.name == RequestStates.PENDING_REJECT || this.state.name == RequestStates.FILLED_IN);
  }

  /**
   * Checks if the request can be rejected.
   * @returns True if this request can be rejected, false otherwise.
   */
  public get canBeRejected(): boolean {
    return (
      this.state.name != RequestStates.PENDING_REJECT &&
      this.state.name != RequestStates.DONE &&
      this.state.name != RequestStates.PENDING_DELETED &&
      this.state.name != RequestStates.CANCELED &&
      this.state.name != RequestStates.FILLED_IN &&
      this.state.name != RequestStates.DISAPPROVED
    );
  }

  /**
   * Checks if the request can be canceled.
   * @returns True if this request can be canceled, false otherwise.
   */
  public get canBeCanceled(): boolean {
    return (
      this.state.name != RequestStates.PENDING_DELETED &&
      this.state.name != RequestStates.DONE &&
      this.state.name != RequestStates.CANCELED &&
      this.state.name != RequestStates.PENDING_REJECT &&
      this.state.name != RequestStates.FILLED_IN &&
      this.state.name != RequestStates.DISAPPROVED
    );
  }

  /**
   * Checks if the request can be deleted.
   * @returns True if this request can be deleted, false otherwise.
   */
  public get canBeDeleted(): boolean {
    return this.state.name == RequestStates.CANCELED || this.state.name == RequestStates.NEW;
  }

  /**
   * Checks if the request is active.
   * @returns True if this request is active, false otherwise.
   */
  public get isActive(): boolean {
    return this.state.name !== RequestStates.NEW && this.state.name !== RequestStates.PENDING_REJECT && this.state.name !== RequestStates.CANCELED;
  }
}
