<div class="tableContainer" [ngClass]="{ loading: loading }">
  <div *ngIf="search && searchTerm">
    <mat-form-field appearance="outline">
      <mat-label>{{ "TABLES.GENERAL.FILTER" | translate }}</mat-label>
      <input [formControl]="searchTerm" matInput placeholder="{{ 'TABLES.GENERAL.FILTER' | translate }}..." />
    </mat-form-field>
  </div>

  <ng-container *ngIf="loading; else table">
    <mat-spinner></mat-spinner>
  </ng-container>
  <mat-paginator *ngIf="pageSizes" [pageSizeOptions]="pageSizes" [ngClass]="{ hidden: dataSource.data.length < pageSizes[0] }"></mat-paginator>
</div>

<ng-template #table>
  <div style="overflow-x: auto">
    <table mat-table [dataSource]="dataSource">
      <ng-container *ngFor="let header of headers" [matColumnDef]="header.visualName">
        <ng-container>
          <th style="min-width: 50px" mat-header-cell *matHeaderCellDef>
            <span>{{ "TABLES." + id + "." + header.visualName | translate }}</span>
          </th>

          <td style="max-width: 150px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap" class="pe-4" mat-cell *matCellDef="let element">
            <ng-container *ngIf="header.mappedTo; else resolvedProperty">
              {{ element[header.mappedTo] }}
            </ng-container>

            <ng-template #resolvedProperty>
              <ng-container *ngIf="header.resolver; else link">
                {{ header.resolver(element) }}
              </ng-container>
            </ng-template>

            <ng-template #link>
              <ng-container *ngIf="header.link; else button">
                <a [routerLink]="makeFullLink(header.link, element)">{{ header.visualName | titlecase }}</a>
              </ng-container>
            </ng-template>

            <ng-template #button>
              <ng-container *ngIf="header.click; else customTemplate">
                <button mat-icon-button [color]="header.click.color" (click)="header.click.clickHandler(element)">
                  <mat-icon [matTooltip]="header.tooltip ?? ''">{{ header.click.icon }}</mat-icon>
                </button>
              </ng-container>
            </ng-template>

            <ng-template #customTemplate>
              <ng-container [ngTemplateOutlet]="header.customTemplate ?? null" [ngTemplateOutletContext]="{ data: element }"> </ng-container>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="visibleHeaders" class="visible"></tr>
      <tr mat-row *matRowDef="let row; columns: visibleHeaders"></tr>

      <tr *matNoDataRow>
        <td colspan="100%">{{ "TABLES.GENERAL.NO_DATA" | translate }}</td>
      </tr>
    </table>
  </div>
</ng-template>
