<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center w-">
      <h2>{{ "FORMS.NEW_REPLACEMENTCLAIMS.TITLE" | translate }}</h2>
      <br />
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" id="messageToRetailer">
              <mat-label>{{ "FORMS.NEW_REPLACEMENTCLAIMS.FIELDS.EXTRA_MESSAGE.LABEL" | translate }}</mat-label>
              <input rows="3" [placeholder]="'FORMS.NEW_REPLACEMENTCLAIMS.FIELDS.EXTRA_MESSAGE.PLACEHOLDER' | translate" matInput formControlName="extraMessage" type="text" />
              <app-error [control]="formGroup.controls['extraMessage']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill" class="example-form-field">
            <mat-label>{{ "GENERAL.MAT_LABEL_DATE_PICKER" | translate }}</mat-label>
            <input [readonly]="true" id="datePickerReplacementClaim" formControlName="date" matInput [min]="currentDate" [max]="maxDate" [matDatepicker]="datepicker" />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
              <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>{{ "DATE_PICKER.CANCEL" | translate }}</button>
                <button mat-raised-button color="primary" matDatepickerApply>{{ "DATE_PICKER.APPLY" | translate }}</button>
              </mat-datepicker-actions>
            </mat-datepicker>
            <app-error [control]="formGroup.controls['date']"></app-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <label for="appt">{{ "COMPONENTS.TIME_PICKER.TIME.TITLE" | translate }} </label>
<!-- This will be changed into a custom time range picker -->
          <input formControlName="time" type="time" id="appt" name="time" required mat-icon="clock" />
          <app-error [control]="formGroup.controls['time']"></app-error>
        </div>
        <br />
      </form>
      <div class="col-12">
        <button id="saveNewReplacementClaim" mat-raised-button [disabled]="!formGroup.valid" (click)="saveNewClaim()">
          {{ "FORMS.NEW_REPLACEMENTCLAIMS.FIELDS.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #popup let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ data }}</h3>
      </div>
    </div>
  </div>
</ng-template>

<!-- <input type="time" id="appt" name="appt"
       min="09:00" max="18:00" required> -->
