import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { ApplicationService } from "../../services/application.service";
import { DialogService } from "../../services/dialog.service";
import { EnergyConsultService } from "../../services/energy-consult.service";
import { ActivatedRoute } from "@angular/router";
import { RequestStates } from "../../classes/flow/request/RequestStates";
import { EnergyConsult } from "../../classes/flow/request/EnergyConsult";

export enum Action {
  ALL,
  OPEN,
  NONE,
}

@Component({
  selector: "app-energy-consult[energyConsult]",
  templateUrl: "./energy-consult.component.html",
  styleUrls: ["./energy-consult.component.less"],
})
export class EnergyConsultComponent implements OnInit {
  @Output() initRequests: EventEmitter<unknown> = new EventEmitter();

  @Input("energyConsult")
  public energyConsult!: EnergyConsult;
  public energyConsultId?: string;

  @Input("inspecting")
  public inspecting?: boolean;

  @Input("actions")
  public actions = Action.ALL;

  @ViewChild("deleteDialog")
  public deleteDialog!: TemplateRef<unknown>;

  @ViewChild("cancelDialog")
  public cancelDialog!: TemplateRef<unknown>;

  public constructor(
    public readonly applicationService: ApplicationService,
    private readonly energyConsultService: EnergyConsultService,
    public readonly dialogService: DialogService,
    private readonly route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.energyConsultId = this.route.snapshot.paramMap.get("id") ?? "";
  }

  /**
   * Checks if a energyConsult can be deleted
   */
  public get energyConsultCanBeDeleted() {
    return !this.checkAction(2) && this.energyConsult.canBeDeleted && this.applicationService.session.activeRole.name === "resident";
  }

  /**
   * Checks if a energyConsult can be canceled
   */
  public get energyConsultCanBeCanceled() {
    return (
      !this.checkAction(2) &&
      this.energyConsult.canBeCanceled &&
      (this.applicationService.session.activeRole.name === "resident" || this.energyConsult.state.name != RequestStates.NEW)
    );
  }

  /**
   * Gets the current state of the energyConsult
   */
  public get currentState() {
    return this.energyConsult.state.name;
  }

  /**
   * Open a cancel energyConsult Dialog
   * @param energyConsultId The id of the energyConsult
   */
  public cancelRequestDialog(energyConsultId: number) {
    this.dialogService.open({
      template: this.cancelDialog,
      data: {
        id: energyConsultId,
      },
    });
  }

  /**
   * Open a delete energyConsult Dialog
   * @param energyConsultId The id of the energyConsult
   */
  public deleteRequestDialog(energyConsultId: number): void {
    this.dialogService.open({
      template: this.deleteDialog,
      data: {
        id: energyConsultId,
      },
    });
  }

  /**
   * Cancel a energyConsult
   */
  public async cancelRequest() {
    this.energyConsult.state.name = RequestStates.CANCELED;
    await this.energyConsultService.setState(this.energyConsult);
    this.initRequests.emit();
  }

  /**
   * Delete a energyConsult
   */
  public async deleteRequest(): Promise<void> {
    if (this.energyConsult.canBeDeleted && this.energyConsult.state.name == RequestStates.NEW && !this.checkAction(Action.NONE)) {
      await this.energyConsultService.delete(this.energyConsult);
      this.initRequests.emit();
      return;
    } else if (this.energyConsult.canBeDeleted && !this.checkAction(Action.NONE)) {
      this.energyConsult.state.name = RequestStates.PENDING_REJECT;
    } else if (this.applicationService.session.activeRole.name === "resident") {
      this.energyConsult.state.name = RequestStates.PENDING_DELETED;
    }

    await this.energyConsultService.save(this.energyConsult, "");
    this.initRequests.emit();
  }

  /**
   * Checks if the given number is equals to the current action
   * @param number The number of the action
   * @returns True if the number is equals to the current action, otherwise false
   */
  public checkAction(number: number): boolean {
    return this.actions == number;
  }
}
