import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ApiService {
  public apiList = new Map<string, Api>();

  /**
   * Returns the corresponding API
   * @param name name of the api: testApi, apimApi of teamsApi
   * @returns
   */
  public getApi(name: string): Api {
    const api = this.apiList.get(name);
    if (!api) throw new Error(`Undefined api: ${name}`);
    return api;
  }
}
interface Header {
  [key: string]: string;
}
export class Api {
  public constructor(private readonly httpClient: HttpClient, private api: string, private defaultHeaders: Header = { "x-ms-max-item-count": "500" }) {}

  public get(endpoint: string, data: unknown = {}, customHeaders: Header = { "x-ms-max-item-count": "500" }): Observable<unknown> {
    const headers = this.createHeaders(this.defaultHeaders, customHeaders);
    return this.httpClient.request("GET", `${this.api}${endpoint}${this.objectToParams(data)}`, {
      headers: headers,
    });
  }

  public post(endpoint: string, data?: unknown, customHeaders: Header = {}, options: Header = { "x-ms-max-item-count": "500" }): Observable<unknown> {
    const headers = this.createHeaders(this.defaultHeaders, customHeaders);
    const mergedOptions = Object.assign({ headers: headers }, options);

    return this.httpClient.post(`${this.api}${endpoint}`, data, mergedOptions).pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  public patch(endpoint: string, data: unknown, customHeaders: Header = {}, options: Header = { "x-ms-max-item-count": "500" }): Observable<unknown> {
    const headers = this.createHeaders(this.defaultHeaders, customHeaders);
    const mergedOptions = Object.assign({ headers: headers }, options);

    return this.httpClient.patch(`${this.api}${endpoint}`, data, mergedOptions).pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  public put(endpoint: string, data: unknown, customHeaders: Header = { "x-ms-max-item-count": "500" }, options: Header = {}): Observable<unknown> {
    const headers = this.createHeaders(this.defaultHeaders, customHeaders);
    const mergedOptions = Object.assign({ headers: headers }, options);

    return this.httpClient.put(`${this.api}${endpoint}`, data, mergedOptions).pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  public createHeaders(defaultHeaders: Header = { "x-ms-max-item-count": "500" }, customHeaders: Header = { "x-ms-max-item-count": "500" }) {
    return new HttpHeaders(Object.assign(defaultHeaders, customHeaders));
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error.message);
  }

  private objectToParams(obj: any): string {
    const params: string[] = [];
    for (const i of Object.keys(obj)) params.push(new HttpParams().set(i, obj[i]).toString());
    return params.length > 0 ? "?" + params.join("&") : "";
  }
}
