import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { filter, Subject, takeUntil } from "rxjs";
import { ApplicationService } from "../../services/application.service";
import { UserService } from "../../services/user.service";
import Logger from "../../classes/Logger";
import { MenuItem } from "../../components/menu/menu";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { HeaderComponent } from "../../components/header/header.component";
import { Router } from "@angular/router";
import { getLanguage } from "../../helpers/determineLanguage";
import { APP_ORGANISATION } from "../../../environments/environment.organisation";

@Component({
  selector: "app-root",
  templateUrl: "./root.html",
  styleUrls: ["./root.less"],
  animations: [
    trigger("menuAnimation", [
      state(
        "hidden",
        style({
          display: "none",
          opacity: "0",
        })
      ),
      state(
        "visible",
        style({
          opacity: "1",
        })
      ),
      transition("* => *", [style({ display: "initial" }), animate("0.12s ease-out")]),
    ]),
  ],
})
export class RootComponent implements OnInit, OnDestroy {
  @ViewChild("header")
  public headerComponent!: HeaderComponent;

  public initialized = false;

  private readonly _destroying$ = new Subject<void>();

  public get menu(): MenuItem[] {
    return this.applicationService.menu;
  }

  public themeName = APP_ORGANISATION.THEME_NAME + "-theme";

  public constructor(
    public readonly authService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly userService: UserService,
    public readonly applicationService: ApplicationService,
    private readonly router: Router
  ) {
    try {
      const app = this.applicationService;
      app.initialize().catch(() => new Error("A fatal error has caused the application to terminate."));
    } catch (err) {
      console.error(err);
    }
  }

  public async ngOnInit() {
    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe(() => {
        this.authService.instance.getAllAccounts().length === 0 ? (window.location.pathname = "/") : null;
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => this.checkAndSetActiveAccount());

    await this.initializeSession();
    this.initialized = true;
  }

  /**
   * Initializes the Session
   */
  private async initializeSession() {
    if (!this.applicationService.session.authenticated && this.userService.getActiveAccount()) {
      try {
        const user = await this.userService.initialize(this.applicationService.session.hasSelectedRole ? this.applicationService.session.activeRole : undefined);
        user.roles = user.roles.filter((role) => role.id == 113).length > 0 ? user.roles.filter((role) => role.id == 113) : user.roles;

        this.applicationService.session.user = user;

        if (user.roles.length === 1 || user.roles.some((r) => this.applicationService.session.activeRole.name === r.name)) {
          if (user.roles.length === 1) {
            await this.applicationService.session.setActiveRole(user.roles[0]);
          }
          this.userService.registerLanguageForUser(user, getLanguage());
          this.applicationService.initMenu();
        } else {
          this.router.navigate(["role-init"], { state: { roles: user.roles, path: this.router.url } });
        }
      } catch (err: any) {
        Logger.error(err);
        if (err.includes("500") || err.includes("403") || err.includes("401")) {
          this.userService.logout();
        }
      }
    }
  }

  public checkAndSetActiveAccount() {
    if (!this.authService.instance.getActiveAccount() && this.authService.instance.getAllAccounts().length > 0)
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
  }

  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
