import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class ReplacementService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  /**
   * gets the resident by id
   * @param id id of the resident
   * @returns resident
   */
  public async getResident(id: number) {
    const result = await this.graphqlService.query(`
      query GetResidentByUser {
        residents {
          value(where: {userId: {eq: ${id}}}) {
            id
          }
          messages{
            message
          }
        }
      }
      `);
    return result.data.residents.value[0].id;
  }

  /**
   * makes a new replacement claim using the input fields filled in by the user
   * @param residentId ID of the resident
   * @param appointmentDate date and time filled in by the resident
   * @param message message filled in by the resident
   * @returns adds the replacementclaim using a mutation
   */
  public async addReplacement(residentId: number, appointmentDate: string, message: string) {
    const result = await this.graphqlService.query(`
      mutation {
        addReplacementClaim(replacementClaim: {
          residentId: ${residentId}
          appointmentDate: "${appointmentDate}"
          message: "${message}"
        }) {
          value {
            id
          }
          messages {
            message
            number
            type
          }
        }
      }
      `);
    if (!result.data.addReplacementClaim.messages.length) return { messages: [] };
    return {
      messages: this.graphqlService.constructMessages(result.data.addReplacementClaim.messages),
    };
  }

  public async getCategories() {
    const result = await this.graphqlService.query(`
      query{
        applianceCategories {
          value {
            id,
            name,
            typeId,
            expired
          }
        }
      }
    `);
    return result.data.applianceCategories.value;
  }

  public async getTypes() {
    const result = await this.graphqlService.query(`
      query{
        applianceTypes {
          value {
            id,
            name,
            code
          }
        }
      }
    `);
    return result.data.applianceTypes.value;
  }
}
