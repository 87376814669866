export default class Logger {
  public static log(message: string, body: unknown = {}) {
    const dateString = new Date().toISOString().replace("Z", "").replace("T", " ");
    console.log(`%c[${dateString}] ${message}`, `padding: 5px 8px; font-size: 14px; color: white; background: ${Logger.makeFancy(message)};`, body);
  }

  public static error(message: string, body: unknown = {}) {
    const dateString = new Date().toISOString().replace("Z", "").replace("T", " ");
    console.log(`%c[${dateString}] ${message}`, "padding: 5px 8px; font-size: 14px; color: white; background: red", body);
  }

  public static makeFancy(msg: string): string {
    let background = "#494949;";

    switch (true) {
      case msg.includes("[User]"):
        background = "#00b3ff";
        break;

      case msg.includes("[Component]"):
        background = "#ab00cc";
        break;

      case msg.includes("[Query]"):
        background = "#ff4081";
        break;
    }

    return background;
  }
}
