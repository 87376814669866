<div class="container d-flex justify-content-center">
    <div class="row w-100">
      <div class="col flex-column text-center">
        <h2>{{ "FORMS.NEW_RETAILER.TITLE" | translate }}</h2>
        <p>{{ "FORMS.NEW_RETAILER.DESCRIPTION" | translate }}</p>
        <br />
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_RETAILER.FIELDS.EMAIL" | translate }}</mat-label>
                <input matInput formControlName="email" placeholder="{{ 'FORMS.NEW_RETAILER.FIELDS.EMAIL' | translate }}" type="email" />
                <app-error [control]="formGroup.controls['email']"></app-error>
              </mat-form-field>
            </div>
        </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_RETAILER.FIELDS.FIRST_NAME" | translate }}</mat-label>
                <input matInput formControlName="firstName" placeholder="{{ 'FORMS.NEW_RETAILER.FIELDS.FIRST_NAME' | translate }}" type="text" />
                <app-error [control]="formGroup.controls['firstName']"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_RETAILER.FIELDS.LAST_NAME" | translate }}</mat-label>
                <input matInput formControlName="lastName" placeholder="{{ 'FORMS.NEW_RETAILER.FIELDS.LAST_NAME' | translate }}" type="text" />
                <app-error [control]="formGroup.controls['lastName']"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_RETAILER.FIELDS.POSTAL_CODE" | translate }}</mat-label>
                <input matInput formControlName="postalCode" placeholder="{{ 'FORMS.NEW_RETAILER.FIELDS.POSTAL_CODE' | translate }}" type="text" />
                <app-error [control]="formGroup.controls['postalCode']"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_RETAILER.FIELDS.CITY" | translate }}</mat-label>
                <input matInput formControlName="city" placeholder="{{ 'FORMS.NEW_RETAILER.FIELDS.CITY' | translate }}" type="text" />
                <app-error [control]="formGroup.controls['city']"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_RETAILER.FIELDS.IBAN" | translate }}</mat-label>
                <input matInput formControlName="iban" placeholder="{{ 'FORMS.NEW_RETAILER.FIELDS.IBAN' | translate }}" type="text" />
                <app-error [control]="formGroup.controls['iban']"></app-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <button mat-raised-button [disabled]="!formGroup.valid" (click)="saveNewRetailer()">{{ "FORMS.NEW_RETAILER.SAVE" | translate }}</button>
      </div>
    </div>
  </div>
  
  <ng-template #popup let-data="data">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md">
          <h3 class="title">{{ data }}</h3>
        </div>
      </div>
    </div>
  </ng-template>