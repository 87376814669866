import { Component, EventEmitter, Output } from "@angular/core";
import { User } from "../../classes/flow/session/impl/User";
import { ApplicationService } from "../../services/application.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.less"],
})
export class HeaderComponent {
  @Output()
  public openMenu = new EventEmitter();

  public constructor(public readonly applicationService: ApplicationService, public readonly userService: UserService) {
  }

  /**
   * Retrieves the active session
   * @returns
   */
  public getSessionActive(): User | undefined {
    return this.applicationService.session.user;
  }

  public async logout() {
    await this.userService.logout();
  }
}
