import { Injectable } from "@angular/core";
import { Retailer } from "../classes/flow/session/impl/Retailer";
import { GraphQLService } from "./graphql.service";
import { Result } from "../classes/system/Result";
import { EmptyResult } from "../classes/system/Result";

@Injectable({
  providedIn: "root",
})
export class RetailersService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  public async addRetailer(retailers: Retailer): Promise<EmptyResult> {
    const result = await this.graphqlService.query(`mutation {
      addRetailer(retailer: {
        firstName: "${retailers.firstName}",
        lastName: "${retailers.lastName}",
        email: "${retailers.email}",
        postalCode: "${retailers.postalCode}",
        city: "${retailers.city}",
        iban: "${retailers.iBAN}"
      }) {
        messages {
          message
          type
        }
      }
    }`);

    return {
      messages: this.graphqlService.constructMessages(result.data.addRetailer.messages),
    };
  }

  // //call query and return its value
  // public async getRetailerById(id: number){
  //   return await this.getRetailerByUser(user);
  // }

  /**
   * Query to check the values from the retailer of the parameters "id"
   * @param user checks the values of the retailer by using the user parameters ID
   * @returns the values from the retailer
   */
  public async getRetailerByUser(user: Retailer): Promise<Result<Retailer>> {
    const res = await this.graphqlService.query(
      `query {
        retailers {
          value(where: {userId: {eq: ${user.userId}}}) {
            id
            email
            firstName
            lastName
            userId
            toegangDatumVanaf
            toegangDatumTM
            active
            registered
            emailSubscription
            changes {
              lastChange {
                userId
                time
              }
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages {
            message
          }
        }
      }`
    );

    return {
      value: res.data.retailers.value?.map((retailer: any) => {
        return new Retailer({
          id: retailer.id,
          userId: retailer.userId,
          roles: user.roles,
          email: retailer.email,
          firstName: retailer.firstName,
          lastName: retailer.lastName,
          city: retailer.city,
          postalCode: retailer.postalCode,
          iBAN: retailer.iBAN,
          accessStartingDate: retailer.toegangDatumVanaf,
          accessEndDate: retailer.toegangDatumTM,
          registered: retailer.registered,
          changes: this.graphqlService.createChangesObject(retailer.changes),
        });
      })?.[0],
      messages: this.graphqlService.constructMessages(res.data.retailers.messages),
    };
  }

  /**
   * query to get all the retailers
   * @returns all the retailers which will be put in a table on the retailer tab
   */
  public async getAllRetailers(): Promise<Array<Retailer>> {
    const res = await this.graphqlService.query(`{
          retailers {
            messages {
              message
            }
            value {
              id,
              email,
              firstName,
              lastName,
              toegangDatumTM,
              toegangDatumVanaf,
              userId,
              registered,
              active
              changes {
                lastChange {
                  userId
                  time
                }
                fullDetails{
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
          }
        }`);
    return res.data.retailers.value?.map((retailer: any) => {
      return new Retailer({
        id: retailer.id,
        userId: retailer.userId,
        email: retailer.email,
        firstName: retailer.firstName,
        lastName: retailer.lastName,
        accessEndDate: retailer.toegangDatumTM,
        accessStartingDate: retailer.toegangDatumVanaf,
        registered: retailer.registered,
      });
    });
  }
}
