import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { EnergyConsult } from "../classes/flow/request/EnergyConsult";
import { ApplicationService } from "./application.service";
import { Coach } from "../classes/flow/session/impl/Coach";
import { EnergyConsultService } from "./energy-consult.service";

@Injectable({
  providedIn: "root",
})
export class CoachEnergyConsultService {
  constructor(
    private readonly graphqlService: GraphQLService,
    private readonly applicationService: ApplicationService,
    private readonly energyConsultService: EnergyConsultService
  ) {}

  public async AcceptEnergyConsult(energyConsult: EnergyConsult, coach?: Coach) {
    const res = await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: { id: ${energyConsult.id}
          set: {
            coachId: ${coach?.id ?? this.applicationService.session.user?.id}
            requestStateId: ${await this.energyConsultService.getEnergyConsultStateId(energyConsult)}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        }) {
          value {
            id
            requestStateId
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
    energyConsult.changes = this.graphqlService.createChangesObject(res.data.updateEnergyConsult.value.changes);
    return res;
  }

  public async getSendReportDate(energyConsult: EnergyConsult) {
    const res = await this.graphqlService.query(
      ` query myQuery{
        energyConsults {
          value (where: {
            id: {eq: ${energyConsult.id}}})
            {
              lastSendDate  
              changes {
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
                lastChange {
                  userId
                  time
                }
              }
            }
          messages{
            message
          }
        }
      }`
    );
    return res.data.energyConsults.value[0].lastSendDate;
  }

  public async setSendReportDate(energyConsult: EnergyConsult) {
    const date = new Date();
    date.setHours(date.getHours() + date.getTimezoneOffset() / -60);
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(
          input: {id: ${energyConsult.id}
            set: {
              lastSendDate: "${date}"
              changes: ${this.graphqlService.formatChangesObject(energyConsult)}
            }
          }
        )
        {
          value {
            id
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Rejects the given energyConsult
   * @param energyConsult The energyConsult to be rejected
   */
  public async rejectEnergyConsult(energyConsult: EnergyConsult) {
    await this.graphqlService.query(
      `mutation {
        updateEnergyConsult(input: {
          id: ${energyConsult.id}
          set: {
            rejectReason: "${energyConsult.rejectReason}"
            requestStateId: ${await this.energyConsultService.getEnergyConsultStateId(energyConsult)}
            changes: ${this.graphqlService.formatChangesObject(energyConsult)}
          }
        })
        {
          value {
            id
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
  }

  public async saveDate(energyConsult: EnergyConsult, date: string) {
    const res = this.graphqlService.query(
      `mutation updateEnergyConsultDate{
        updateEnergyConsult(input:
          {
            id: ${energyConsult.id}
            set:{
              requestStateId: ${await this.energyConsultService.getEnergyConsultStateId(energyConsult)},
              appointmentDate: "${date}"
              changes: ${this.graphqlService.formatChangesObject(energyConsult)}
            }
          }
        )
        {
          messages{
            message
          }
        }
      }`
    );
    return res;
  }
}
